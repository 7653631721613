import React, { Component, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Input, FormFeedback, InputGroup, Collapse } from "reactstrap";
import ClientCaptcha from 'react-client-captcha';

import { useFormik } from "formik";
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import { completeFormThunk } from "slices/bqeth/thunk";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";

const getCurrentDate= () => {
  return new Date().toISOString();
}

// ANY CHANGES MADE HERE PROBABLY NEED TO ALSO CHANGE IN Professionals.tsx

const FormPartner = () => {

  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch<any>();
  const [cookies, ] = useCookies(['userUuid']);
  const [statesOpen, setStatesOpen] = useState(false);
  const [userCaptchaInput, setUserCaptchaInput] = useState("");
  const [captchaCode, setCaptchaCode] = useState('');

  function toggleStates() {
    setStatesOpen(!statesOpen)
  }

  const formik: any = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      firm: "",
      phone: "",
      types: [],
      states: [],
      tosHash: "None-Direct from Form."
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("This field is required"),
      lastname: Yup.string().required("This field is required"),
      email: Yup.string().required("This field is required"),
      firm: Yup.string().required("This field is required"),
      phone: Yup.string().required("This field is required"),
      types: Yup.array().min(1, "At least one type is required"),
      states: Yup.array().min(1,"At least one Jurisdiction is required"),
    }),

    onSubmit: (values: any) => {
      console.log(JSON.stringify(values, null, 2));
      setSubmitted(true); // Close it up
      if (userCaptchaInput === captchaCode) {
        dispatch(completeFormThunk({ 
          user_hash: cookies['userUuid'], 
          date: getCurrentDate(),
          form: 1,
          email: values.email,
          response: values
        }));
      }
      else {
        alert('Captcha Does Not Match');
        setUserCaptchaInput("");
    }
    },
  });

  
  const types = ["Estate", "Tax", "Trust", "Family office", "Other"];
  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District Of Columbia",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
    "American Samoa",
    "Guam",
    "Northern Mariana Islands",
    "Puerto Rico",
    "United States Minor Outlying Islands",
    "Virgin Islands"
  ];
  

  const handleTypeChange = (e) => {
    const { checked, name } = e.target;
    if (checked) {
      formik.setFieldValue("types", [...formik.values.types, name]);
    } else {
      formik.setFieldValue(
        "types",
        formik.values.types.filter((v) => v !== name)
      );
    }
  };

  const handleJurisChange = (e) => {
    const { checked, name } = e.target;
    if (checked) {
      formik.setFieldValue("states", [...formik.values.states, name]);
    } else {
      formik.setFieldValue(
        "states",
        formik.values.states.filter((v) => v !== name)
      );
    }
  };


  return (
    <React.Fragment>
            <div className="modal-body">
              <h5>BqETH Trusted Advisor Program Signup</h5>
              <p>
                We will contact you and use the information you provide us below in accordance with BqETH Trusted Advisor Program documents.
              </p>
              <Card>
                <CardBody>
                  <CardTitle className="h5 mb-4">Please provide the following:</CardTitle>
                  {submitted ? <div>Submitted. Thank you.
                    <br>If you prefer to complete your initial training (summary and Quiz) at no cost with an instructor, 
                    sign up for the T100-Trusted Partner Program Certification (1 hr) <a href="/webinars">here</a></br>
                  </div>: 
                  <Form className="row gy-2 gx-3 align-items-center" onSubmit={formik.handleSubmit}>
                    <Row className="mb-4" >
                      <Label className="form-check-label" htmlFor="autoSizingFNameInput">First Name</Label>
                      <Col sm={9}>
                      <Input type="text" name="firstname" className="form-control" id="autoSizingFNameInput" placeholder="Jane"
                        value={formik.values.firstname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={
                          formik.touched.firstname && formik.errors.firstname ? true : false
                        } />
                      {
                        formik.errors.firstname && formik.touched.firstname ? (
                          <FormFeedback type="invalid">{formik.errors.firstname}</FormFeedback>
                        ) : null
                      }                    
                      </Col>
                    </Row>
                    <Row className="mb-4" >
                      <Label className="form-check-label" htmlFor="autoSizingLNameInput">Last Name</Label>
                      <Col sm={9}>
                      <Input type="text" name="lastname" className="form-control" id="autoSizingLNameInput" placeholder="Doe"
                        value={formik.values.lastname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={
                          formik.touched.lastname && formik.errors.lastname ? true : false
                        } />
                      {
                        formik.errors.lastname && formik.touched.lastname ? (
                          <FormFeedback type="invalid">{formik.errors.lastname}</FormFeedback>
                        ) : null
                      }                    
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label className="form-check-label" htmlFor="autoSizingEmailInput">Your Email</Label>
                      <Col sm={9}>
                      <InputGroup>
                        <div className="input-group-text">@</div>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          id="autoSizingEmailInput"
                          placeholder="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur} />
                      </InputGroup>
                      {
                        formik.errors.email && formik.touched.email ? (
                          <span className="text-danger">{formik.errors.email}</span>
                        ) : null
                      }
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label className="form-check-label" htmlFor="autoSizingFirmInput">Your Firm</Label>
                      <Col sm={9}>
                      <Input type="text" name="firm" className="form-control" id="autoSizingFirmInput" placeholder=""
                        value={formik.values.firm}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={
                          formik.touched.firm && formik.errors.firm ? true : false
                        } />
                      {
                        formik.errors.firm && formik.touched.firm ? (
                          <FormFeedback type="invalid">{formik.errors.firm}</FormFeedback>
                        ) : null
                      }
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label className="form-check-label" htmlFor="autoSizingInputGroup">Your Phone</Label>
                      <Col sm={9}>
                      <InputGroup>
                        <div className="input-group-text">#</div>
                        <input
                          type="tel"
                          name="phone"
                          className="form-control"
                          id="autoSizingInputGroup"
                          placeholder="phone"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur} />
                      </InputGroup>
                      {
                        formik.errors.phone && formik.touched.phone ? (
                          <span className="text-danger">{formik.errors.phone}</span>
                        ) : null
                      }
                      </Col>
                    </Row>

                    <Row className="mb-4">
                      <label className="form-check-label" >Practice type</label>
                      {types.map((type) => (
                        <div key={type}>
                          <input className="form-check-input"
                            id={type}
                            type="checkbox"
                            name={type}
                            checked={formik.values.types.includes(type)}
                            onChange={handleTypeChange}
                          />
                          <label className="form-check-label" htmlFor={type}>{type}</label>
                        </div>
                      ))}
                      {
                      formik.errors.types && formik.touched.types ? (
                        <span className="text-danger">{formik.errors.types}</span>
                      ) : null
                      }
                    </Row>

                    <Row className="mb-4" >
                      <div id="gen-ques-accordion" className="accordion custom-accordion">
                      <Link
                              to="#"
                              className="accordion-list"
                              onClick={() => {toggleStates()}}
                              style={{ cursor: "pointer" }}
                            >
                              <label className="form-check-label" htmlFor="stateSelect">Select Licensed Jurisdiction(s)</label>
                              <i style={{color:"darkgray"}}
                                className={
                                  statesOpen
                                    ? "mdi mdi-minus accor-plus-icon"
                                    : "mdi mdi-plus accor-plus-icon"
                                }
                              />
                      </Link>
                      </div>
                      <Collapse isOpen={statesOpen}>
                      {states.map((juris) => (
                        <div key={juris}>
                          <input className="form-check-input"
                            id={juris}
                            type="checkbox"
                            name={juris}
                            checked={formik.values.states.includes(juris)}
                            onChange={handleJurisChange}
                          />
                          <label className="form-check-label" htmlFor={juris}>{juris}</label>
                        </div>
                      ))}
                      </Collapse>
                      {
                        formik.errors.states && formik.touched.states ? (
                          <span className="text-danger">{formik.errors.states}</span>
                        ) : null
                      }
                    </Row>
                    <Row className="mb-4" >
                        <div className="col mt-3">
                            <ClientCaptcha backgroundColor="#F2F2F2" fontSize="18" fontFamily="DejaVu Sans Mono" charsCount='5' captchaCode={setCaptchaCode} />
                        </div>
                        <div className="col mt-3">
                            <div>
                              <p>Attention: captcha is case sensitive.</p>
                              <input style={{fontFamily:"DejaVu Sans Mono"}}
                                      placeholder="Enter Captcha Value" 
                                      id="user_captcha_input" 
                                      name="user_captcha_input" 
                                      value={userCaptchaInput} 
                                      type="text"
                                      onChange={(event) => {
                                          setUserCaptchaInput(event.target.value)
                                        }}
                                      />
                              </div>
                        </div>
                      </Row>

                    <div className="col-sm-auto">
                      <button type="submit" className="btn btn-primary w-md"
                      >Submit</button>
                    </div>
                  </Form>
                  }
                </CardBody>
              </Card>
            </div>
    </React.Fragment>
  )
}
export default FormPartner
