//import Images
import jdbertron from "../../assets/images/users/JDBertron.jpg";
import marcsolomon from "../../assets/images/users/MarcSolomon.jpg";
import atillabaksay from "../../assets/images/users/AtillaBaksay.jpg";

const icoLandingTeam = [
  {
    id: 1,
    img: jdbertron,
    author: "J.D. Bertron",
    post: "Founder & CEO",
    fb: undefined,
    in: "https://www.linkedin.com/in/jdbertron/",
    tg: "https://t.me/JDismantle",
    x: "https://twitter.com/bqethCrypto"
  },
  {
    id: 2,
    img: marcsolomon,
    author: "Marc Solomon",
    post: "Founder & Chief Financial Officer",
    in: "https://www.linkedin.com/in/marcsolomonmfb/",
    fb: undefined,
    tg: undefined,
    x: undefined
  },
  {
    id: 3,
    img: atillabaksay,
    author: "Atilla Baksay",
    post: "Chief Legal Officer",
    in: "https://www.linkedin.com/in/atilla-baksay-9065a110a/"
  },
];

// Some Java like hashCode function from the interwebs
const hashCode = function(s) {
  return s.split("").reduce(function(a, b) {
    a = ((a << 5) - a) + b.charCodeAt(0);
    return a & a;
  }, 0);
}

export { icoLandingTeam, hashCode }
