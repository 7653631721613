import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Progress,
  Tooltip,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";

//Import Countdown
import Countdown from "react-countdown";
import logolight from "../../../../assets/images/BqETHLogoCroppedWhtJust.png";
import whitepaper from "../../../../assets/docs/BqETH-White-Paper-2.0.pdf";
import lightpaper from "../../../../assets/docs/BqETH-Litepaper-2.0.pdf";
//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import "../../../../../node_modules/swiper/swiper.scss";

const HeroSection = () => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>You are good to go!</span>;
    } else {
      return (
        <>
          <div className="counter-number ico-countdown">
            <div id="days" className="coming-box">
              {days}
              <span>Days</span>
            </div>
            <div id="hours" className="coming-box">
              {hours}
              <span>Hours</span>
            </div>
            <div id="mins" className="coming-box">
              {minutes}
              <span>Hours</span>
            </div>
            <div id="secs" className="coming-box">
              {seconds}
              <span>Seconds</span>
            </div>
            <div id="end" className="h1"></div>
          </div>
        </>
      );
    }
  };

  // Tooltip Open state
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const carouselQuestions = [
    { id: 1,  post: "Easiest to use",},
    { id: 2,  post: "Censorship-proof",},
    { id: 3,  post: "Mathematically Verified",},
    { id: 4,  post: "Absolutely Trustless",},
    { id: 5,  post: "Bulletproof reliability",},
    { id: 6,  post: "Store Any Secret",},
    { id: 7,  post: "Any coin or token",},
    { id: 8,  post: "Non custodial",},
    { id: 9,  post: "Safest for you",},
    { id: 10, post: "Safest for your heirs",},
    { id: 11, post: "Absolute proof of death",},
  ];


  return (
    <React.Fragment>
      <section className="section hero-section bg-bqeth-hero" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <Row className="align-items-center">
            <Col lg={7}>
              <div className="text-white-50">
                <h1 className="text-white fw-semibold mb-3 hero-title">
                <Tooltip isOpen={tooltipOpen} placement="top-start" target="Logo"
                toggle={() => { setTooltipOpen(!tooltipOpen) }} >
                Pronounced "bequeath"</Tooltip>
                <img src={logolight} id="Logo"alt="BqETH Logo" 
                height="80"  className="card-logo card-logo-dark"/>Digital Inheritance </h1>
                <p className="text-white fw-normal mb-3 ">Utterly Trustless, Impenetrable, Uncensorable and Private, unlike any other solution</p>
                <p className="text-white fw-normal mb-3 ">BqETH is the ultimate blockchain-based "Safe Deposit Box" that will protect your crypto secrets until you die.  We will deliver them to your heirs </p>
                <p className="text-white fw-normal mb-3 ">Works with any digital currency, token, or file of your choosing</p>

                <div className="d-flex flex-wrap gap-2 mt-4">
                  <button className="btn btn-bqeth-teal"><NavLink href={lightpaper} >Get Whitepaper</NavLink></button>
                  <button className="btn btn-light"><NavLink href="#intro" >See how it works</NavLink></button>
                </div>
              </div>
            </Col>
            <Col lg={5} md={8} sm={10} className="ms-md-auto">
            <Swiper
              slidesPerView={1}
              spaceBetween={25}
              navigation
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              // breakpoints={{
              //   678: {
              //     slidesPerView: 2,
              //   },
              //   992: {
              //     slidesPerView: 3,
              //   },
              //   1400: {
              //     slidesPerView: 3,
              //   }
              // }}              
              loop={true}
              modules={[Pagination, Navigation, Autoplay]}
              className="owl-carousel owl-theme events navs-carousel" id="questions-carousel" dir="ltr">
                {carouselQuestions.map((item, key) => (
                  <SwiperSlide key={key} className="item">
                    <Card className="text-center card-yellow" outline={false} >
                      <CardBody >
                          <h2>{item.post}</h2>
                      </CardBody>
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>
              {/* <Card className="overflow-hidden mb-0 mt-5 mt-lg-0">
                <CardHeader className="text-center">
                  <h5 className="mb-0">Release Countdown time</h5>
                </CardHeader>
                <CardBody>
                  <div className="text-center">

                    <h5>Time left to Release :</h5>
                    <div className="mt-4">
                      <div className="counter-number ico-countdown"></div>
                      <Countdown date="2024/02/01" renderer={renderer} />
                    </div>

                    <div className="mt-4">
                      <button type="button" className="btn btn-bqeth-teal w-md">Get Notified</button>
                    </div>

                    <div className="mt-5"> */}
                      {/* <h4 className="fw-semibold">1 ETH = 2235 SKT</h4> */}
                      {/* <div className="clearfix mt-4">
                        <h5 className="float-end font-size-14">5234.43</h5>
                      </div> */}
                      {/* <div className="progress p-1 progress-xl softcap-progress">
                        <Progress value={30} color="primary" style={{ width: '30%' }} animated></Progress>
                      </div>
                    </div>
                  </div>
                </CardBody> 
              </Card>*/}
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default HeroSection;
