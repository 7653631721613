import { APIClient } from "./api_helper";
import * as url from "./url_helper";

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
export const postFakeRegister = (data: any) => {
  return api.create(url.POST_FAKE_REGISTER, data)
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
export const postFakeLogin = (data: any) => api.create(url.POST_FAKE_LOGIN, data);

// postForgetPwd
export const postFakeForgetPwd = (data: any) => api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = (data: any) => api.create(url.POST_EDIT_JWT_PROFILE, data);

export const postFakeProfile = (data: any) => api.create(url.POST_EDIT_PROFILE, data);


// Register Method
export const postJwtRegister = (url: any, data: any) => {
  return api.create(url, data)
    .catch((err: any) => {
      var message: any;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
export const postJwtLogin = (data: any) => api.create(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
export const postJwtForgetPwd = (data: any) => api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = (data: any) => api.create(url.SOCIAL_LOGIN, data);

export const getStarredMails = () => api.get(url.GET_STARRED_MAILS, null);

export const getImportantMails = () => api.get(url.GET_IMPORTANT_MAILS, null);

// get starredmail
export const getDraftMails = () => api.get(url.GET_DRAFT_MAILS, null);

// get inboxmail
export const getInboxMails = () => api.get(url.GET_INBOX_MAILS, null);

// get sent mail
export const getSentMails = () => api.get(url.GET_SENT_MAILS, null);

// get trash mail
export const getTrashMails = () => api.get(url.GET_TRASH_MAILS, null);

// delete Mail
export const deleteMail = (mail: any) => api.delete(url.DELETE_MAIL, { headers: { mail } });

// get wallet
export const getWallet = () => api.get(url.GET_WALLET, null);
//get wallwr / activities
export const getWalletActivities = () => api.get(url.GET_WALLET_ACTIVITIES, null);

//// get contacts
export const getUsers = () => api.get(url.GET_USERS, null);
export const addNewUser = (user: any) => api.create(url.ADD_NEW_USERS, user);
export const updateUser = (user: any) => api.put(url.UPDATE_USERS, user);
export const deleteUsers = (users: any) => api.delete(url.DELETE_USERS, { headers: { users } });
export const getUserProfile = () => api.get(url.GET_USER_PROFILE, null);

// CRM
// api.get Contacts
export const getContacts = () => api.get(url.GET_CONTACTS, null);

export const getBqETHData = () => api.get(url.GET_BQETH_DATA, null);
export const newBqETHUser = (data: any) => api.post(url.CREATE_BQETH_USER, 
{
  user_hash: data.user_hash,
  first_visit: data.first_visit
});


// BqETH Stuff 
export const visitBqETHUser = (data: any) => api.post(url.VISIT_BQETH_USER, 
{
  user_hash: data.user_hash,
  date: data.new_visit
});

export const getBqETHFAQs = () => api.get(url.GET_BQETH_FAQ, null);
export const getBqETHTopicList = () => api.get(url.GET_TOPIC_LIST, null);

// export const getBqETHNextTopics = (data: any) => api.post(url.GET_BQETH_NEXT_TOPICS, 
// {
//   user_hash: data.user_hash
// });

export const getBqETHLastTopic = (data: any) => api.post(url.GET_BQETH_LAST_TOPIC, 
  {
    user_hash: data.user_hash
  });

export const visitBqETHTopic = (data: any) => api.post(url.VISIT_BQETH_TOPIC, 
{
  user_hash: data.user_hash,
  date: data.date,
  topic: data.topic,
  quiz_good: data.quiz_good
});

export const completeForm = (data: any) => api.post(url.FORM_COMPLETION, 
  {
    user_hash: data.user_hash,
    date: data.date,
    form_id: data.form,
    email: data.email,
    response: JSON.stringify(data.response)
  });

export const nextTopicsFromTopic = (data: any) => api.get(url.NEXT_TOPICS_FROM_TOPIC, 
  {
    topic: data.topic
  });

export const prevTopicFromTopic = (data: any) => api.get(url.PREV_TOPIC_FROM_TOPIC, 
  {
    topic: data.topic
  });


export const getTopic = (data: any) => api.post(url.GET_TOPIC, 
  {
    topic: data.topic
  });

export const getQuiz = (data: any) => api.post(url.GET_QUIZ, 
{
  quiz: data.quiz
});

export const getUserQuiz = (data: any) => api.post(url.GET_USER_QUIZ, 
  {
    user_hash: data.user_hash,
    topic: data.topic
});

export const getPartnerQuiz = (data: any) => api.post(url.GET_PARTNER_QUIZ, 
  {
    user_hash: data.user_hash,
    topic: data.topic
});

export const getUserIsReady = (data: any) => api.post(url.GET_USER_ISREADY, 
  {
    user_hash: data.user_hash
});

export const getPartnerIsReady = (data: any) => api.post(url.GET_PARTNER_ISREADY, 
  {
    user_hash: data.user_hash
});

export const setPartnerIsActive = (data: any) => api.post(url.SET_PARTNER_ISACTIVE, 
  {
    tpp_email: data.tpp_email
});

export const findMe = (data: any) => api.post(url.FIND_ME, 
  {
    email: data.email
});

export const getBqETHWebinars = () => api.get(url.WEBINARS, null);