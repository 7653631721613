
import React, { useEffect } from 'react';

declare global {
  interface Window {
    $pagesense: any;
  }
}
const PageSense: React.FC = () => {
  useEffect(() => {
    // // Check if the PageSense object exists and initialize if not
    // if (!window.$pagesense) {
    //   window.$pagesense = {};
    // }
    // if (!window.$pagesense.pagesense) {
    //   window.$pagesense.pagesense = { ready: function() {} };
    // }

    // Create a script element for PageSense
    const script = document.createElement('script');
    script.src = 'https://cdn.pagesense.io/js/ka7ekmco/fbc8b95c7604492ebd47eb023fde39fa.js';
    script.id = 'pagesensescript';

    const head = document.querySelector("head");
    // Append the script to the body
    if (head)  {
      head.appendChild(script);
    }
    // Cleanup the script when the component unmounts
    // return () => {
      // document.getElementById('pagesensescript')?.remove();
    // };
  }, []);

  return null; // This component doesn't render anything itself
};

export default PageSense;
