import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    getBqETHData,
    newBqETHUser,
    visitBqETHUser,
    getBqETHFAQs,
    // getBqETHNextTopics,
    getBqETHLastTopic,
    visitBqETHTopic,
    nextTopicsFromTopic,
    getTopic,
    getQuiz,
    getBqETHTopicList, 
    prevTopicFromTopic, 
    getUserQuiz,
    getUserIsReady,
    completeForm,
    getPartnerIsReady,
    getPartnerQuiz,
    findMe,
    getBqETHWebinars,
    setPartnerIsActive
} from "../../helpers/backend_helper"

export const getBqETHDataThunk = createAsyncThunk("bqeth/getBqETHData", async () => {
    try {
        const response = getBqETHData();
        return response;
    } catch (error) {
        return error;
    }
})

export const newBqETHUserThunk = createAsyncThunk("bqeth/newBqETHUser", async (data: any) => {
    try {
        const response = newBqETHUser(data);
        return;
    } catch (error) {
        return error;
    }
})

export const visitBqETHUserThunk = createAsyncThunk("bqeth/visitBqETHUser", async (data : any) => {
    try {
        const response = visitBqETHUser(data);
        return;
    } catch (error) {
        return error;
    }
})

export type TopicItem = {
    id: string;
    fields: {
        quiz: string;
        topic_id: number;
        order: string;
        topic_tree: string;
        ShortTitle: string;
        Filtering: string[];
        Component: string;
        FullTitle: string;
        direction: string;
        topic_id_label: string;
    },
    direction: string;
  };

  export type QuizItem = {
    id: string;
    fields: {
        question_id: number;
        question_content: string;
        incorrect_answer_text: string;
        correct_answer_text: string;
        correct_answer_is: number;
        quiz: string;   // this is a record
        QShort: string;
        app_mandatory: boolean; // will launch the app in TEST mode unless true
        must: boolean;          // Must be answered to enable the next button
        num_answers: number;
        tpp_mandatory: boolean; // will graduate a TPP partner
    }
  };

export const getBqETHFAQThunk = createAsyncThunk("bqeth/getBqETHFAQ", async () => {
    try {
        const response: TopicItem[] = getBqETHFAQs();
        return response;
    } catch (error) {
        return error;
    }
})

export const getBqETHTopicListThunk = createAsyncThunk("bqeth/getBqETHTopicList", async () => {
    try {
        const response: TopicItem[] = getBqETHTopicList();
        return response;
    } catch (error) {
        return error;
    }
})

// export const getBqETHNextTopicThunk = createAsyncThunk("bqeth/getBqETHNextTopic", async (data: any) => {
//     try {
//         const response: TopicItem[] = getBqETHNextTopics(data);
//         return response;
//     } catch (error) {
//         return error;
//     }
// })

export const getBqETHLastTopicThunk = createAsyncThunk("bqeth/getBqETHLastTopic", async (data: any) => {
    try {
        const response: TopicItem[] = getBqETHLastTopic(data);
        return response;
    } catch (error) {
        return error;
    }
})

export const visitBqETHTopicThunk = createAsyncThunk("bqeth/visitBqETHTopic", async (data: any) => {
    try {
        const response: TopicItem[] = visitBqETHTopic(data);
        return response;
    } catch (error) {
        return error;
    }
})

export const completeFormThunk = createAsyncThunk("bqeth/completeForm", async (data: any) => {
    try {
        const response: string = completeForm(data);
        return response;
    } catch (error) {
        return error;
    }
})

export const getBqETHNextTopicsFromTopicThunk = createAsyncThunk("bqeth/getBqETHNextTopicsFromTopic", async (data: any) => {
    try {
        const response: TopicItem[] = nextTopicsFromTopic(data);
        return response;
    } catch (error) {
        return error;
    }
})

export const getBqETHPrevTopicFromTopicThunk = createAsyncThunk("bqeth/getBqETHPrevTopicFromTopic", async (data: any) => {
    try {
        const response: TopicItem = prevTopicFromTopic(data);
        return response;
    } catch (error) {
        return error;
    }
})

export const getBqETHTopicThunk = createAsyncThunk("bqeth/getBqETHTopic", async (data: any) => {
    try {
        const response: TopicItem[] = getTopic(data);
        return response;
    } catch (error) {
        return error;
    }
})

export const getBqETHQuizThunk = createAsyncThunk("bqeth/getBqETHQuiz", async (data: any) => {
    try {
        const response: QuizItem[] = getQuiz(data);
        return response;
    } catch (error) {
        return error;
    }
})

export const getBqETHUserQuizThunk = createAsyncThunk("bqeth/getBqETHUserQuiz", async (data: any) => {
    try {
        const response: boolean = getUserQuiz(data);
        return response;
    } catch (error) {
        return error;
    }
})

export const getBqETHPartnerQuizThunk = createAsyncThunk("bqeth/getBqETHPartnerQuiz", async (data: any) => {
    try {
        const response: boolean = getPartnerQuiz(data);
        return response;
    } catch (error) {
        return error;
    }
})

export const getBqETHUserReadyThunk = createAsyncThunk("bqeth/getBqETHUserReady", async (data: any) => {
    try {
        const response: boolean = getUserIsReady(data);
        return response;
    } catch (error) {
        return error;
    }
})

export const getBqETHPartnerReadyThunk = createAsyncThunk("bqeth/getBqETHPartnerReady", async (data: any) => {
    try {
        const response: boolean = getPartnerIsReady(data);
        return response;
    } catch (error) {
        return error;
    }
})

export const setBqETHPartnerActiveThunk = createAsyncThunk("bqeth/setBqETHPartnerActive", async (data: any) => {
    try {
        await new Promise(resolve => setTimeout(resolve, 30000))
        const response: boolean = setPartnerIsActive(data);
        return response;
    } catch (error) {
        return error;
    }
})

export const findMeThunk = createAsyncThunk("bqeth/findMe", async (data: any) => {
    try {
        const response: boolean = findMe(data);
        return response;
    } catch (error) {
        return error;
    }
})

export const getBqETHWebinarsThunk = createAsyncThunk("bqeth/getBqETHWebinars", async () => {
    try {
        const response = getBqETHWebinars();
        return response;
    } catch (error) {
        return error;
    }
})