//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//CRYPTO
export const GET_WALLET = "/wallet";
//activities
export const GET_WALLET_ACTIVITIES = "wallet-activies";

//CONTACTS
export const GET_USERS = "/apps/users";
export const ADD_NEW_USERS = "/apps/users";
export const UPDATE_USERS = "/apps/users";
export const DELETE_USERS = "/delete/users/";
export const GET_USER_PROFILE = "/user";

// Contact
export const GET_CONTACTS = "/apps/contact";

// Crypto
export const GET_CRYPTO_ORDRER_LIST = "/crypto-order-list";

//Mails
export const GET_INBOX_MAILS = "/inboxmails";

//starred mail
export const GET_STARRED_MAILS = "/starredmails";

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails";

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails";

//Send mail
export const GET_SENT_MAILS = "/sentmails";

//Trash mail
export const GET_TRASH_MAILS = "/trashmails";

//Delete mail
export const DELETE_MAIL = "/mail/delete";

export const GET_EARNING_DATA = "/earning-charts-data";

export const TOP_SELLING_DATA = "/top-selling-data";

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data";
export const GET_YEARLY_DATA = "/yearly-data";
export const GET_MONTHLY_DATA = "/monthly-data";

export const DAPP="https://bqeth.xyz/"
// Local testing:
export const GET_BQETH_DATA = "https://bqeth.com/api/users";
export const GET_BQETH_FAQ = "https://bqeth.com/api/faqs";
export const GET_TOPIC_LIST = "https://bqeth.com/api/topicList";
export const CREATE_BQETH_USER = "https://bqeth.com/api/newUser";
export const VISIT_BQETH_USER = "https://bqeth.com/api/userVisit";
// export const GET_BQETH_NEXT_TOPICS = "https://bqeth.com/api/nextTopicForUser";
export const VISIT_BQETH_TOPIC = "https://bqeth.com/api/userInteraction";
export const NEXT_TOPICS_FROM_TOPIC = "https://bqeth.com/api/nextTopicsFromTopic";
export const PREV_TOPIC_FROM_TOPIC = "https://bqeth.com/api/prevTopicFromTopic";
export const GET_TOPIC = "https://bqeth.com/api/getTopic";
export const GET_QUIZ = "https://bqeth.com/api/getQuiz";
export const GET_USER_QUIZ = "https://bqeth.com/api/getUserQuiz";
export const GET_PARTNER_QUIZ = "https://bqeth.com/api/getPartnerQuiz";
export const GET_USER_ISREADY = "https://bqeth.com/api/userIsReady";
export const GET_PARTNER_ISREADY = "https://bqeth.com/api/partnerIsReady";
export const SET_PARTNER_ISACTIVE = "https://bqeth.com/api/setPartnerActive";
export const GET_BQETH_LAST_TOPIC = "https://bqeth.com/api/lastTopicForUser";
export const FORM_COMPLETION = "https://bqeth.com/api/formCompletion";
export const FIND_ME = "https://bqeth.com/api/findMe";
export const WEBINARS = "https://bqeth.com/api/webinars";
