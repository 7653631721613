import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Collapse, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TopicItem, getBqETHTopicThunk, visitBqETHTopicThunk } from "slices/thunk";
import { useCookies } from "react-cookie";
import ImageComponent from "../RenderComponents/ImageComponent";
import { createSelector } from "reselect";

const LEARN_MSECONDS = 15000; // How long we wait foran open FAQ topic to be opened before recording it

const FAQccordeon = (props: any) => {

  const [rows, setRows] = useState(
    Array.from({ length: props.entries.length }, (_, index) => index === -1)  // Nothing open by default
  );
  const [currentTopic, setCurrentTopic] = useState<number | undefined>();
  const dispatch = useDispatch<any>();
  const [cookies, ] = useCookies(['userUuid']);

  const toggleRow = (index: number) => {
    setRows((prevRow) =>
      prevRow.map((col, i) => (i === index ? !col : false))
    );
    // Make sure we record that the topic was visited 
    if (rows[index]) {
      setCurrentTopic(undefined); // Was open, being closed
    }
    else {
      setCurrentTopic(props.entries[index].topic);  // Was closed, being opened
    }
  };

  useEffect(() => {
      // Use setTimeout to update the message after 2000 milliseconds (2 seconds)
      const timeoutId = setTimeout(() => {
        if (currentTopic) {
          console.log('Recording that we visited FAQ topic: ', currentTopic, ' after 15 seconds.');
          // Create/Update user interaction  (when the user moves off the topic and into the next)
          dispatch(visitBqETHTopicThunk({ 
            user_hash: cookies['userUuid'],     
            date: new Date().toISOString(),
            topic: currentTopic,
            quiz_good: undefined
          }));
        }
      }, LEARN_MSECONDS);
  
      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timeoutId);
    }, [currentTopic]);


  // Handling of topic Links inside the current topic content

  const [topicModal, setTopicmodal] = useState<boolean>(false);
  const [modalTopicId, setModalTopicId] = useState<number|undefined>(undefined);
  const toggle = () => setTopicmodal(!topicModal);

  // If the modal needs to display a topic, fetch it
  useEffect(() => {
    if(modalTopicId) {
      console.log("Fetching topic:", modalTopicId);
      setModalContent("");
      dispatch(getBqETHTopicThunk({ "topic": modalTopicId}));
    }
  }, [modalTopicId]);

  // Selectors for the Current Topic 
  const selectCurrentTopic = createSelector(
    (state: any) => state.bqeth.topics,
    (topics) => topics
  );
  const modaltopic: TopicItem = useSelector(selectCurrentTopic);

  const [modalTitle,setModalTitle] = useState<string>("");
  const [modalContent,setModalContent] = useState<string>("");

  // Whenever there is a modal topic to show, modaltopic changes
  useEffect(() => {
    if (modaltopic && modaltopic.fields) {
      // When modaltopic has a value
      console.log("Modal topic changed:", modaltopic)
      setModalTitle(modaltopic.fields.FullTitle);
      setModalContent(modaltopic.fields.Component);
    }
  }, [modaltopic]);

    const TopicComponent = ({ content }) => {

      const renderContent = () => {
        if (content) {
          const regex = /(\[T\d+T*\])/g;  // Matches Topic links [T23] or [T0T]  
          const parts = content.split(regex);
  
          if (parts) {
            return parts.map((part, index) => {
              if (regex.test(part)) {
                const tn = part.match(/\d+/)[0];
                const key = 'IT-'+index;
                return (
                    <button key={key} className="anchor-button" 
                    onClick={() => {
                        console.log(tn);
                        setModalTopicId(Number(tn));
                        setTopicmodal(true);
                      }}>
                      here
                    </button>
                );
              } 
              else {
                const key = 'I-'+index;
                return <ImageComponent key={key} content={part} />
              }
            });
          }
        }
        else {  // Empty topic, return something.
          return <div></div>
        }
      };
    
      return <>{renderContent()}</>;
    };

  return (
    <React.Fragment>
      <div>
        <div id="gen-ques-accordion" className="accordion custom-accordion">
          {props.entries.map((entry, index) => (
            <div className="mb-3" key={index}>
              <Link
                to="#"
                className="accordion-list"
                onClick={() => toggleRow(index)}
                style={{ cursor: "pointer" }}
              >
                <div>{entry.question}</div>
                <div style={{color:"#eff2f7"}}>{entry.topic}-{entry.order}</div>
                <i
                  className={
                    rows[index]
                      ? "mdi mdi-minus accor-plus-icon"
                      : "mdi mdi-plus accor-plus-icon"
                  }
                />
              </Link>
              <Collapse isOpen={rows[index]}>
                <Card className="border plan-box " styles={[{ marginTop: 16 }]}>
                  <CardBody className="p-5">
                    <TopicComponent content={entry.answer} /> 
                    {/* If there are embedded topic links, use a modal */}
                  </CardBody>
                </Card>
              </Collapse>
            </div>
          ))}
          <Modal isOpen={topicModal} autoFocus={true} size="lg" backdrop={true} centered toggle={toggle}>
            <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
            <ModalBody>
              <Card className="border plan-box " styles={[{ marginTop: 16 }]}>
                <CardBody className="p-5">
                  <TopicComponent content={modalContent} />
                </CardBody>
              </Card>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FAQccordeon;
