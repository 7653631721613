import React from "react";

import {
  Container,
} from "reactstrap"

import StaticNavbar from "../Navbar/StaticNavBar";
import ZohoSalesIQ from "../Professionals/SalesIQ";
import PageSense from "pages/Crypto/CryptoIcoLanding/Professionals/PageSense";

const Blank = (props: any) => {

  return (
    <React.Fragment>
      <StaticNavbar imglight={false}/>
      <section className="section" id="landing">
        <Container>
          <ZohoSalesIQ />
          <PageSense />
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Blank;