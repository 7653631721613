import { hashCode } from "common/data/crypto";
import FormButtonComponent from "./FormComponent";


  // Called by ImageComponent to replace Links within non-image parts
  const TopicLinkComponent = ({ content }) => {


    const renderContent = () => {
      if (content) {
        const regex = /(\[L.*?\])/g;
        const parts = content.split(regex);

        if (parts) {
          return parts.map((part, index) => {
            if (regex.test(part)) {
              const link = part.match(/['"”].*?['"”]/);
              if (link && link[0]) {
                const unquoted: string  = link[0].replace(/['"”]+/g, '');
                  return (  // [Lhttps://localhost:8080/faq]
                      <a href={unquoted} key={hashCode(unquoted)} target="_blank" rel="noreferrer" > here </a>
                  );
              } 
              else {
                return ("Link Syntax Error:"+part);
              }
            } else {
              const key = 'F-'+index;
              return <FormButtonComponent key={key} content={part} text="here"/>
            }
          });
        }
      }
      else {  // Empty topic, return something.
        return <div></div>
      }
    };
  
    return <>{renderContent()}</>;
  };

export default TopicLinkComponent;