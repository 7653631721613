const eula = `<html>
<head>
	<meta http-equiv="content-type" content="text/html; charset=utf-8"/>
	<title></title>
	<meta name="generator" content="LibreOffice 6.4.7.2 (Linux)"/>
	<meta name="created" content="00:00:00"/>
	<meta name="changed" content="2024-04-04T09:45:29.053485316"/>
	<style type="text/css">
		@page { size: 8.5in 11in; margin-left: 1in; margin-right: 1in; margin-top: 1in; margin-bottom: 0.5in }
		@page:first { }
		p { margin-bottom: 0.1in; direction: ltr; color: #000000; line-height: 115%; text-align: justify; orphans: 2; widows: 2; background: transparent; text-decoration: none }
		p.western { font-family: "Arial", serif; font-size: 11pt; font-style: normal; font-weight: normal; text-align:justify }
		p.cjk { font-family: "Arial"; font-size: 11pt; font-style: normal; font-weight: normal }
		p.ctl { font-family: "Arial"; font-size: 11pt; font-style: normal; font-weight: normal }
	</style>
</head>
<body lang="en-US" text="#000000" link="#000080" vlink="#800000" dir="ltr"><p >
<font face="Times New Roman, serif"><font size="4" style="font-size: 14pt"><span lang="en-US"><u><b>BqETH</b></u></span></font></font></p><p >
<font face="Times New Roman, serif"><font size="4" style="font-size: 14pt"><span lang="en-US"><u><b>END-USER
LICENSE AGREEMENT</b></u></span></font></font></p>
<p >
<br/>

</p>
<p >
<u><b>PLEASE
READ THE BINDING ARBITRATION CLAUSE AND CLASS ACTION WAIVER LOCATED
IN THIS END-USER LICENSE AGREEMENT, AS THEY AFFECT HOW DISPUTES
BETWEEN YOU AND BqETH, INC. ARE RESOLVED.</b></u></span></font></font></p>
<p >
<br/>

</p>
<p class="western" text- style="margin-bottom: 0in">This
End-User License Agreement (this “Agreement”) describes your (“you” or the “<b>Subscriber</b>”) rights and the conditions pursuant to which you may use the BqETH
website-based software service (“<b>BqETH</b>”), whether through a web browser or an application, and governs your relationship with BqETH, Inc., the company providing the BqETH
software service (the “<b>Company</b>”). You should review this Agreement in its entirety, including any supplemental license terms that accompany the BqETH software and any linked terms, as all such the terms are important and, together, form this Agreement that applies to you.
<br/>

</p>
<p >
<u><b>BY
CLICKING “I AGREE” AND/OR USING THE BqETH SOFTWARE, YOU AGREE TO
EACH AND ALL OF THE BELOW TERMS AND CONSENT TO THE TRANSMISSION OF
CERTAIN INFORMATION DURING YOUR UTILIZATION OF, OR SUBSCRIPTION TO,
THE BqETH SOFTWARE. IN THE EVENT YOU DO NOT ACCEPT AND COMPLY WITH
THESE TERMS, YOU MAY NOT USE THE BqETH SOFTWARE OR ITS SOURCE CODE OR
FEATURES.</b></u></span></font></font></p>
<p >
<br/>

</p>
<p >
<b>License</b></span></font></font></p>
<p >
<br/>

</p>
<ol>
	<li><p >
	<u>Overview</u></span></font></font>.
	</span></font></font>
	</p>
</ol>
<p class="western" text- style="margin-left: 0.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<li><p >
		<i>Brief
		Description of BqETH</i></span></font></font>.
		BqETH is a web- and blockchain-based information storage
		software-as-a-service product owned and provided by the Company. It
		allows you to store information with the purpose of making that
		information available to a third-party non-subscriber in the event
		of your passing. You purchase BqETH’s information storage service
		for a manually selected period of time, upon which you can renew
		your subscription or allow the timer to expire. You may also cancel
		your subscription prior to the expiration of the timer. When the
		timer for a subscription expires and is not renewed, the
		information is made available to the third-party non-subscriber.</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="2">
		<li><p >
		<i><span style="background: transparent">Cancellation
		of BqETH Service</span></i></span></font></font><span style="background: transparent">.
		In the event you no longer wish to utilize BqETH, you may cancel
		your subscription per the applicable instructions found on the
		BqETH website.</span></span></font></font><span style="background: transparent">
		Upon </span></span></font></font>cancellation,
		the information you originally stored will be permanently replaced
		by a base value providing no utility or method of tracing the
		originally stored information. As a result, the mechanism powering
		BqETH will not be affected, while the originally stored information
		will never become visible to third parties.</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="3">
		<li><p >
		<i>Applicability</i></span></font></font>.
		This Agreement applies to you while utilizing, or subscribing to,
		the BqETH software, including any updates, upgrades, supplements,
		or services for BqETH, unless other terms apply and explicitly
		preclude this Agreement.</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="4">
		<li><p >
		<i>Accounts</i></span></font></font>.
		While, generally, the BqETH website does not require you to create
		an account, certain specific areas and/or services within BqETH or
		its host website may make account creation possible and/or required
		to access such areas or utilize such services. For more information
		related to account creation, utilization, management, and deletion,
		pl</span></font></font><span style="background: transparent">ease
		review the </span></span></font></font><span style="background: transparent">BqETH
		Terms of Service on the BqETH website</span></span></font></font><span style="background: transparent">.</span></span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="5">
		<li><p >
		<i>Compliance</i></span></font></font>.
		By executing this Agreement, you agree that you will comply with:</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<ol type="i">
			<li><p >
			All
			instructions and requirements in the BqETH Terms of Service,
			Privacy Policy, CCPA Notice, GDPR Notice, each as amended by the
			Company from time to time, and other documentation that the
			Company provides or makes available to you in connection with
			BqETH (the “</span></font></font><b>Documentation</b></span></font></font>”);
			and</span></font></font></p>
		</ol>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<ol type="i" start="2">
			<li><p >
			All
			applicable local, state, national, and international laws and
			regulations (as each of these may be amended or modified from time
			to time) that may apply to you (collectively, “</span></font></font><b>Applicable
			Law</b></span></font></font>”)
			with respect to your utilization of BqETH.</span></font></font></p>
		</ol>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="6">
		<li><p >
		<i>Third-Party
		Software</i></span></font></font>.
		BqETH or its host website may utilize or include software or
		integrations developed, owned, or provided by third parties or
		their licensors (including open-source software) (“</span></font></font><b>Third-Party
		Software</b></span></font></font>”).
		Utilization of Third-Party Software is governed by separate
		licenses as indicated in the license, notice, readme, or other
		files or notices of such Third-Party Software. Your license rights
		with respect to Third-Party Software are defined by the applicable
		Third-Party Software license(s), and nothing in this Agreement
		shall increase, restrict, limit, or otherwise affect any rights or
		obligations you may have or the conditions to which you may be
		subject under such Third-Party Software licenses. By executing this
		Agreement, you expressly acknowledge and understand that the
		Company does not control the terms and conditions of each
		applicable Third-Party Software license, and you release the
		Company from any obligation or liability related to such
		Third-Party Software license. In the event you do not agree to be
		bound by and subject to the terms and conditions of each applicable
		Third-Party Software license, you must terminate this Agreement by
		canceling your subscription to BqETH or refraining from utilizing
		BqETH. Notwithstanding the foregoing, in the event the Company’s
		rights from a licensor of Third-Party Software are limited,
		suspended, or terminated for any reason, your rights will also be
		correspondingly limited, suspended, or terminated.</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol start="2">
	<li><p >
	<u>Limited
	Software License</u></span></font></font>.</span></font></font></p>
</ol>
<p class="western" text- style="margin-left: 0.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<li><p >
		<i>Limited
		License</i></span></font></font>.
		Under this Agreement, the Company grants you a non-exclusive,
		limited, revocable software license to utilize BqETH on your
		browser, so long as you comply with all the terms of this
		Agreement. BqETH is licensed to you for your personal,
		non-commercial use, unless you have commercial use rights under a
		separate agreement with the Company. The Company may make
		substantial or complete changes to BqETH (including coding,
		purpose, or accessibility) before, during, or after your 
		subscription to BqETH and shall not be liable to you whatsoever for
		such change(s) beyond its liability to you as otherwise stated in
		this Agreement. </span></font></font>
		</p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="2">
		<li><p >
		<i>Restrictions</i></span></font></font>.
		The Company reserves all rights (including intellectual property
		rights) not expressly granted to you in this Agreement. You agree
		that you will not utilize BqETH in any manner other than as
		permitted by this Agreement or in a manner inconsistent with
		BqETH’s design or the Documentation. For example, this license
		does not give you any right to, and you may not, without
		limitation:</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<ol type="i">
			<li><p >
			Transfer
			your specific BqETH subscription; or</span></font></font></p>
		</ol>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<ol type="i" start="2">
			<li><p >
			Use
			BqETH’s features in any way that could interfere with anyone
			else’s use of them, or to try to gain access to or use any
			service, data, account, or network, in an unauthorized manner.</span></font></font></p>
		</ol>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="3">
		<li><p >
		<i>Use
		in Virtualized Environment</i></span></font></font>.
		This Agreement permits you to utilize BqETH on any legal and
		compliant device and browser, whether such device or browser is
		physical or virtual. In the event you would like to utilize BqETH
		on more than one device, you must ensure that each such device is
		legal and compliant with Applicable Law. Furthermore, BqETH may
		utilize digital certificates to help you sign and validate
		signatures within portable document format (“</span></font></font><b>PDF</b></span></font></font>”)
		documents and to validate certified PDF documents. As part of the
		foregoing, you understand and agree that your device may connect to
		the Internet at the time of validation of a digital certificate.</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="4">
		<li><p >
		<i>Export
		Control</i></span></font></font>.
		You may not utilize, directly or indirectly: </span></font></font>
		</p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<ol type="i">
			<li><p >
			In
			any countries that are subject to U.S. export restrictions
			(including, without limitation, Cuba, Iran, North Korea, Sudan,
			and Syria);</span></font></font></p>
		</ol>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<ol type="i" start="2">
			<li><p >
			As
			any end-user who would utilize BqETH in the design, development,
			or production of nuclear, chemical, or biological weapons, or
			rocket systems, space launch vehicles, and sounding rockets, or
			unmanned air vehicle systems; or</span></font></font></p>
		</ol>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<ol type="i" start="3">
			<li><p >
			As
			any end-user who has been prohibited from participating in U.S.
			export transactions by any federal agency of the U.S. government. </span></font></font>
			</p>
		</ol>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
In
addition, you are responsible for complying with any local laws in
your jurisdiction, which may impact your right to utilize BqETH. The
Company may implement en masse, good-faith measures to ensure the
provisions of this section (such as IP-blocking measures).</span></font></font></p>
<p >
<br/>

</p>
<ol start="3">
	<li><p >
	<u>Data</u></span></font></font>.</span></font></font></p>
</ol>
<p >
<br/>

</p>
<ol>
	<ol type="a">
		<li><p >
		<i>Updates</i></span></font></font>.
		BqETH may periodically automatically apply software and/or
		application updates without your prior notice. By clicking “I
		Agree,” you acknowledge and understand the aforementioned
		sentence. In the event you refuse any such updates, you may no
		longer be able to utilize BqETH.</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="2">
		<li><p >
		<i>Networks,
		Data, and Internet usage</i></span></font></font>.
		Most features of BqETH and services accessed through BqETH require
		your device to access the Internet. Your Internet access and usage
		(including charges) may be subject to the terms of your cellular or
		internet service provider agreement. BqETH’s usage of Internet
		bandwidth calculations may be different from your service
		provider’s measurements. You are responsible for understanding
		and complying with the terms of your plans and agreements, as well
		as any issues arising from using or accessing networks, including
		public/open networks.</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 0.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="3">
		<li><p >
		<i>Data
		Privacy</i></span></font></font>.
		The Company will only use Subscriber Information (as defined below)
		in accordance with th</span></font></font><span style="background: transparent">e
		</span></span></font></font><span style="background: transparent">BqETH
		Privacy Policy</span></span></font></font><span style="background: transparent">,
		</span></span></font></font><span style="background: transparent">BqETH
		CCPA Notice, and BqETH GDPR Notice</span></span></font></font><span style="background: transparent">,
		eac</span></span></font></font>h
		as amended by the Company from time to time, and, by executing this
		Agreement, you expressly consent to such usage (please visit the
		BqETH website to review each of these policies). You further
		acknowledge and agree that Subscriber Information supplied by you
		may be transferred to other countries or jurisdictions outside of
		your jurisdiction(s) of residence, and that the protections
		afforded such information under the laws and regulations of the
		jurisdiction to which the information is transferred may not be
		comparable to, or as protective as, the protections afforded such
		information in your jurisdiction of residence. </span></font></font>
		</p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
For
purposes of this Agreement, “</span></font></font><b>Subscriber
Information</b></span></font></font>”
shall mean information about you that you provide to the Company in
connection with this Agreement, including, without limitation, names,
usernames, email addresses, cryptocurrency wallet addresses, and
billing information.</span></font></font></p>
<p class="western" text- style="margin-left: 0.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol start="4">
	<li><p >
	<u>Term,
	Termination, and Refunds</u></span></font></font>.</span></font></font></p>
</ol>
<p class="western" text- style="margin-left: 0.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<li><p >
		<i>Term</i></span></font></font>.
		This Agreement becomes effective between you and the Company at the
		date and time you click “I agree” and remains in effect until
		it expires or is terminated in accordance herewith. In the event
		you extend the term of this Agreement prior to its preset
		termination date, the remaining amounts paid by you under the
		original subscription period will be credited to the new term.</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="2">
		<li><p >
		<i>Termination</i></span></font></font>.
		Either you or the Company may terminate this Agreement with
		immediate effect, with or without cause. To terminate this
		Agreement, you must cancel your BqETH subscription by following the
		applicable prompts on the BqETH website.</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 0.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="3">
		<li><p >
		<i>Effects
		of Termination</i></span></font></font>.
		Upon expiration or termination of this Agreement, all of your
		rights under this Agreement immediately terminate, and the Company
		will have no further obligation to provide you the BqETH service.</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="4">
		<li><p >
		<i>Refunds</i></span></font></font>.
		Upon termination, the Company will refund you the unearned portion
		of the service fees charged for your subscription. All refunds take
		the form of cryptocurrencies sent to the wallet from which they
		were originally paid. You will receive the equivalent
		cryptocurrency price of the amount paid by you for the refundable
		portion of the service. </span></font></font>
		</p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 0.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol start="5">
	<li><p >
	<u>Limited
	Warranty and Disclaimers</u></span></font></font>.</span></font></font></p>
</ol>
<p class="western" text- style="margin-left: 0.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<li><p >
		<i>Limited
		Warranty</i></span></font></font>.
		Subject to the provisions of this section, the Company warrants
		that properly licensed BqETH will perform substantially as
		described in the Documentation. This limited warranty is not
		transferable and does not cover:</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<ol type="i">
			<li><p >
			Damages,
			defects, malfunctions, or failures caused by any unauthorized
			modification of the BqETH source code by you or your agents;</span></font></font></p>
		</ol>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<ol type="i" start="2">
			<li><p >
			Damages,
			defects, malfunctions, or failures caused by your utilization of
			BqETH in a manner inconsistent with this Agreement and/or the
			Documentation;</span></font></font></p>
		</ol>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<ol type="i" start="3">
			<li><p >
			Any
			abuse, misuse, or negligent acts by you or your agents;</span></font></font></p>
		</ol>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<ol type="i" start="4">
			<li><p >
			Modification
			by you or your agents of any interfaces or any software or
			hardware interfacing with BqETH;</span></font></font></p>
		</ol>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<ol type="i" start="5">
			<li><p >
			Any
			failure by you or your agents to follow BqETH’s configuration,
			utilization, or operation instructions, as set forth in the
			Documentation (including failure to install any updates required
			by the Company from time to time). </span></font></font>
			</p>
		</ol>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
The
limited warranty takes effect when you click “I Agree” to this
Agreement and remains in effect for the term of your BqETH
subscription. The Company also makes this limited warranty for BqETH
updates.</span></font></font></p>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="2">
		<li><p >
		<i>Disclaimers</i></span></font></font>.
		</span></font></font><u><b>EXCEPT
		AS EXPRESSLY SET FORTH IN THE PREVIOUS SUBSECTION, BqETH IS
		DELIVERED TO YOU BY THE COMPANY “AS IS” WITH ALL FAULTS AND
		WITHOUT FURTHER WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. THE
		COMPANY DISCLAIMS ALL FURTHER WARRANTIES, EXPRESS OR IMPLIED,
		INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
		MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, QUIET
		ENJOYMENT, NON-INFRINGEMENT, AND ANY WARRANTIES OR CONDITIONS
		ARISING FROM ANY ACTUAL OR ALLEGED COURSE OF DEALING, USAGE, OR
		TRADE PRACTICE. MOREOVER, THE COMPANY DOES NOT WARRANT OR REPRESENT
		THAT BqETH WILL OPERATE UNINTERRUPTED, BE ERROR FREE, OR THAT
		DEFECTS IN BqETH ARE CORRECTABLE OR WILL BE CORRECTED IN ALL
		INSTANCES. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY THE
		COMPANY OR AN AUTHORIZED REPRESENTATIVE OF THE COMPANY WILL CREATE
		SUCH A WARRANTY. </b></u></span></font></font>
		</p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<u><b>FURTHERMORE,
ANY THIRD-PARTY SOFTWARE ASSOCIATED WITH THE UTILIZATION OF BqETH IS
PROVIDED TO YOU “AS-IS” WITHOUT ANY WARRANTY OF ANY KIND FROM THE
COMPANY. YOU MAY HAVE ADDITIONAL RIGHTS REGARDING SUCH THIRD-PARTY
SOFTWARE DEPENDING ON ANY ADDITIONAL AGREEMENT(S) YOU MAY HAVE WITH
THE OWNER(S) OF SUCH THIRD-PARTY SOFTWARE, BUT IN NO EVENT DOES THE
COMPANY ISSUE ANY SUCH WARRANTIES OR REPRESENTATIONS REGARDING SUCH
THIRD-PARTY SOFTWARE. </b></u></span></font></font>
</p>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<u><b>THE
LAWS OF CERTAIN JURISDICTIONS DO NOT ALLOW, OR LIMIT, THE DISCLAIMER
OF IMPLIED WARRANTIES. IN THE EVENT THESE LAWS APPLY TO YOU, SOME OR
ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY BE
LIMITED OR INAPPLICABLE, AND YOU MAY HAVE ADDITIONAL RIGHTS.</b></u></span></font></font></p>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="3">
		<li><p >
		<u>High-Risk
		Applications</u></span></font></font>.
		</span></font></font><u><b>BqETH
		IS NOT DESIGNED, MANUFACTURED, OR INTENDED FOR USE IN ENVIRONMENTS
		REQUIRING FAULT TOLERANCE OR FAIL-SAFE PERFORMANCE, SUCH AS IN THE
		OPERATION OF NUCLEAR FACILITIES, AIRCRAFT NAVIGATION, MEDICAL OR
		COMMUNICATION SYSTEMS, AIR TRAFFIC CONTROL, DIRECT LIFE SUPPORT
		MACHINES, OR WEAPONS SYSTEMS, IN WHICH THE FAILURE OF THE SOFTWARE
		COULD LEAD DIRECTLY TO DEATH, PERSONAL INJURY, OR SEVERE PHYSICAL
		OR ENVIRONMENTAL DAMAGE (COLLECTIVELY, “HIGH-RISK APPLICATIONS”).
		THE COMPANY SPECIFICALLY DISCLAIMS ANY EXPRESS OR IMPLIED WARRANTY
		OF FITNESS FOR HIGH-RISK APPLICATIONS.</b></u></span></font></font></p>
	</ol>
</ol>
<p >
<br/>

</p>
<ol start="6">
	<li><p >
	<u>Indemnification</u></span></font></font>.</span></font></font></p>
</ol>
<p class="western" text- style="margin-left: 0.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<li><p >
		<i>Indemnification
		of Company by You</i></span></font></font>.
		You shall indemnify, defend, and hold harmless the Company from and
		against all claims, suits, and proceedings and any and all related
		liabilities, losses, expenses, damages, and costs (including,
		without limitation, reasonable attorneys’ fees) (collectively,
		“</span></font></font><b>Losses</b></span></font></font>”)
		incurred by the Company, relating to or arising out of your breach
		of this Agreement. You shall cooperate reasonably with the Company
		and allow the Company to control the defense or settlement thereof.</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="2">
		<li><p >
		<i>Limited
		Indemnification of You by Company for Intellectual Property
		Infringement Claims</i></span></font></font>.
		The Company will defend, hold harmless, and indemnify you against
		any Losses from a third-party allegation that your utilization of
		BqETH infringes or misappropriates such third party’s
		intellectual property rights </span></font></font><i>resulting
		from the acts of the Company, not resulting information you may
		have stored using BqETH or your other acts and/or omissions</i></span></font></font>.
		You must promptly notify the Company in writing of any allegation
		that preceded the proceeding and cooperate reasonably with the
		Company to resolve such allegation and proceeding. In the event a
		breach of this subsection prejudices the defense of the third-party
		legal proceeding, the Company’s obligations hereunder shall be
		reduced in proportion to the prejudice. You must tender sole
		control of the indemnified portion of the third-party legal
		proceeding to the Company, subject to the following:</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<ol type="i">
			<li><p >
			You
			may appoint your own non-controlling counsel, at your own expense;
			and</span></font></font></p>
		</ol>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<ol type="i" start="2">
			<li><p >
			Any
			settlement requiring you to admit liability, pay money, or take
			(or refrain from taking) any action, will require your prior
			written consent, not to be unreasonably withheld, conditioned, or
			delayed.</span></font></font></p>
		</ol>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
For
purposes of clarification, the Company shall have no duty to
indemnify, hold harmless, or defend you for any claims of violated
intellectual property rights as a result of </span></font></font><i>your</i></span></font></font>
acts, such as, for example, storing information copyrighted by others
on BqETH.</span></font></font></p>
<p class="western" text- style="margin-left: 1.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol start="7">
	<li><p >
	<u>Force
	Majeure</u></span></font></font>.
	In no event shall the Company be responsible or liable to you or any
	third party for any failure or delay in the performance of its
	obligations arising out of this Agreement caused by, directly or
	indirectly, forces beyond its control, including, without
	limitation: natural disasters; uncontrollable malicious acts of
	third parties (such as hacking or theft of equipment); strikes or
	work stoppages; acts of war or terrorism; civil or military
	disturbances; nuclear or natural catastrophes or acts of God;
	epidemics, pandemics, and other health-related disasters; utility
	interruptions; local, state, or national government acts (including
	mandates or actions concerning a matter of public health, weather,
	or natural hazard); delays or conflicts with shippers, logistics
	providers, or brokers; or malfunctions of utilities, communications,
	or computer (software and hardware) services, including as a result
	of hacking, spyware, malware, ransomware, frontrunning,
	denial-of-service, and phishing attacks.</span></font></font></p>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol start="8">
	<li><p >
	<u>Governing
	Law and Disputes</u></span></font></font>.</span></font></font></p>
</ol>
<p class="western" text- style="margin-left: 0.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<li><p >
		<i>Governing
		Law</i></span></font></font>.
		This Agreement and all related documents including all schedules
		attached hereto, and all matters arising out of or relating to this
		Agreement, whether sounding in contract, tort, statute, or
		otherwise, are governed by, and construed in accordance with the
		laws of the State of Delaware and the United States of America
		(including the State of Delaware’s statutes of limitations and 
		choice of law statutes), without giving effect to the conflict of
		laws provisions thereof to the extent such principles or rules
		would require or permit the application of the laws of any
		jurisdiction other than those of the State of Delaware and the
		United States of America.</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="2">
		<li><p >
		<i>Arbitration</i></span></font></font>.
		</span></font></font><u><b>IN
		THE EVENT OF ANY DISPUTE BETWEEN YOU AND THE COMPANY THAT IS NOT
		AMICABLY RESOLVED, YOU AND THE COMPANY SHALL PROCEED TO BINDING
		ARBITRATION BEFORE ONE (1) ARBITRATOR OF JAMS. IN THE EVENT YOU AND
		THE COMPANY FAIL TO AGREE ON THE IDENTITY OF THE ARBITRATOR, THE
		ARBITRATOR SHALL BE ASSIGNED BY JAMS. ANY ARBITRATION SHALL BE
		CONDUCTED IN ACCORDANCE WITH JAMS’ COMPREHENSIVE ARBITRATION
		RULES AND PROCEDURES. UNLESS OTHERWISE AGREED TO BY THE PARTIES IN
		WRITING, THE SEAT OF ARBITRATION SHALL BE DENVER, COLORADO (THOUGH
		ANY PARTY MAY ATTEND REMOTELY), AND ARBITRATION SHALL SOLELY BE
		CONDUCTED IN ENGLISH.</b></u></span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="3">
		<li><p >
		<i>Dispute-Related
		Expenses</i></span></font></font>.
		You and the Company shall each bear their own dispute-related
		expenses, and attorneys’ fees may not be awarded by the
		arbitrator to either party unless the arbitrator determines the
		other party to have acted in bad faith by commencing such a
		dispute.</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="4">
		<li><p >
		<i>Jury
		Trial Waiver</i></span></font></font>.
		</span></font></font><u><b>TO
		THE FULL EXTENT PERMITTED BY LAW, YOU AND THE COMPANY HEREBY
		EXPRESSLY WAIVE ANY AND ALL RIGHT TO A TRIAL BY JURY ON THE ISSUE
		TO ENFORCE ANY TERM OR CONDITION OF THIS AGREEMENT.</b></u></span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="5">
		<li><p >
		<i>Class-Action
		Waiver</i></span></font></font>.
		</span></font></font><u><b>ANY
		PROCEEDINGS TO RESOLVE OR LITIGATE ANY DISPUTE ARISING FROM THE
		PROVISIONS OF THIS AGREEMENT SHALL BE CONDUCTED SOLELY ON AN
		INDIVIDUAL BASIS. NEITHER YOU NOR THE COMPANY SHALL SEEK TO HAVE
		ANY DISPUTE HEARD AS A CLASS ACTION, PRIVATE ATTORNEY GENERAL
		ACTION, OR IN ANY OTHER PROCEEDING IN WHICH EITHER YOU OR THE
		COMPANY ACTS OR PROPOSES TO ACT IN A REPRESENTATIVE CAPACITY.</b></u></span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="6">
		<li><p >
		<i>Consequential
		Damages</i></span></font></font>.
		</span></font></font><u><b>THE
		COMPANY SHALL NOT BE LIABLE TO YOU UNDER ANY CAUSE OF ACTION OR
		THEORY OF LIABILITY FOR:</b></u></span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<ol type="i">
			<li><p >
			<u><b>INDIRECT,
			INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES;</b></u></span></font></font></p>
		</ol>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<ol type="i" start="2">
			<li><p >
			<u><b>THE
			VALUE OF YOUR CONTENT;</b></u></span></font></font></p>
		</ol>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<ol type="i" start="3">
			<li><p >
			<u><b>LOSS
			OF PROFITS, REVENUES, CUSTOMERS, OPPORTUNITIES, OR GOODWILL; OR</b></u></span></font></font></p>
		</ol>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<ol type="i" start="4">
			<li><p >
			<u><b>UNAVAILABILITY
			OF THE SOFTWARE, SUPPORT SERVICES, OR PROFESSIONAL SERVICES, </b></u></span></font></font>
			</p>
		</ol>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<u><b>EVEN
IF THE COMPANY HAS BEEN ADVISED OF, OR SHOULD HAVE KNOWN OF, THE
POSSIBILITY OF SUCH DAMAGES.</b></u></span></font></font></p>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="7">
		<li><p >
		<i>Limitation
		on Damages</i></span></font></font>.
		</span></font></font><u><b>THE
		COMPANY’S AGGREGATE LIABILITY TO YOU ARISING UNDER OR IN RELATION
		TO THIS AGREEMENT SHALL BE LIMITED TO THE LESSER OF ACTUAL DIRECT
		DAMAGES OR THE ACTUAL FEES PAID BY YOU TO THE COMPANY DURING THE
		TWELVE (12) CALENDAR MONTH PERIOD PRIOR TO ANY INCIDENT OR CLAIM
		UNDER WHICH OR IN RELATION TO WHICH THE LIABILITY ARISES.</b></u></span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol start="9">
	<li><p >
	<u>Miscellaneous</u></span></font></font>.</span></font></font></p>
</ol>
<p class="western" text- style="margin-left: 0.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a">
		<li><p >
		<i>Notices</i></span></font></font>.
		The Company may provide any notice to you under this Agreement by
		sending an email to the most recent email address the Company has
		on file. Notices the Company provides by email shall be deemed
		effective when your email server receives such notice or, in the
		event the server is not functioning for any reason, the date and
		time when the Company’s server sends the email. It is your
		responsibility to keep your email address up to date.</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
All
notices, requests, consents, claims, demands, waivers, and other
communications hereunder shall be in writing and addressed to the
Company as set forth in this section.  All notices shall be delivered
by internationally recognized overnight courier (with all fees
prepaid).  Except as otherwise provided in this Agreement, a notice
is effective only if the Company has received the notice and you have
complied with the requirements of this section. Notice is deemed
received at the date and time the Company receives such notice from
the courier at the following address:</span></font></font></p>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<p class="western" text- style="margin-left: 2in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
BqETH,
Inc.</span></font></font></p>
<p class="western" text- style="margin-left: 2in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
℅ Northwest
Registered Agent, LLC</span></font></font></p>
<p class="western" text- style="margin-left: 2in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
8
The Green</span></font></font></p>
<p class="western" text- style="margin-left: 2in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
Suite
B</span></font></font></p>
<p class="western" text- style="margin-left: 2in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
Dover,
Delaware 1990</span></font></font></p>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
All
communications and notices made or given to the Company pursuant to
this Agreement shall be in the English language. </span></font></font>
</p>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="2">
		<li><p >
		<i>Conflicting
		Languages</i></span></font></font>.
		In the event the Company provides a translation of the English
		language version of this Agreement, the English language version of
		the Agreement shall control in the event of any conflict.</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="3">
		<li><p >
		<i>Conflicts</i></span></font></font>.
		In the event of a conflict between one or more provisions of this
		Agreement and one or more provisions of another agreement (verbal
		or written) between you and the Company, the provisions of this
		Agreement shall prevail and govern to the extent of the conflict
		unless the provisions of this section are directly and explicitly
		disclaimed by you and the Company in such other agreement.</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="4">
		<li><p >
		<i>No
		Agency or Other Relationship</i></span></font></font>.
		This Agreement does not create any agency, partnership, company,
		employer-employee, contractor, or joint venture relationship
		between you and the Company. Neither you nor the Company have the
		authority to bind the other without such party’s prior written
		consent.</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; text-indent: -0.5in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="5">
		<li><p >
		<i>Change-In-Control</i></span></font></font>.
		The Company may experience a change-in-control at any time and
		without your prior notice or consent. Such change-in-control shall
		not affect the provisions of this Agreement and shall be deemed an
		automatic assignment and assumption of this Agreement in its
		entirety, including all rights and obligations, by the original
		form and/or ownership of the Company to the new form and/or
		ownership of the Company.</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="6">
		<li><p >
		<i>No
		Third-Party Beneficiaries</i></span></font></font>.
		Except as set forth explicitly in this Agreement, this Agreement
		does not create any third-party beneficiary rights in any
		individual or entity that is not a party to this Agreement.</span></font></font></p>
	</ol>
</ol>
<p >
<br/>

</p>
<ol>
	<ol type="a" start="7">
		<li><p >
		<i>Amendment</i></span></font></font>.
		This Agreement may be amended, supplemented, superseded, or
		otherwise modified at any time by the Company. In the event you do
		not agree with such modification(s), you may immediately terminate
		this Agreement in accordance with the provisions hereof.</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="8">
		<li><p >
		<i>Severability</i></span></font></font>.
		In the event any term or provision of this Agreement is held
		invalid, illegal, or unenforceable in any jurisdiction, such
		invalidity, illegality, or unenforceability shall not affect any
		other term or provision of the Agreement or invalidate or render
		unenforceable such term or provision in any other jurisdiction.</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="9">
		<li><p >
		<i>Waiver</i></span></font></font>.
		No failure by the Company to insist upon the strict performance of
		any covenant, duty, agreement, or condition of this Agreement or to
		exercise any right or remedy consequent upon a breach thereof shall
		constitute waiver of any such breach of any other covenant, duty,
		agreement, or condition.</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="10">
		<li><p >
		<i>Counterparts</i></span></font></font>.
		You and the Company may execute this Agreement in multiple
		counterparts, each of which shall be deemed an original, and all of
		which taken together shall constitute one and the same instrument.
		Delivery of an executed counterpart of this Agreement via PDF, or
		by any other electronic means shall have the same effect as
		delivery of an executed original of the Agreement.</span></font></font></p>
	</ol>
</ol>
<p class="western" text- style="margin-left: 1in; margin-bottom: 0in; border: none; padding: 0in; line-height: 115%">
<br/>

</p>
<ol>
	<ol type="a" start="11">
		<li><p >
		<i>Other
		BqETH Policies</i></span></font></font>.
		</span></font></font><span style="background: transparent">This
		Agreement operates in conjunction with </span></span></font></font><font color="#000000"><span style="text-decoration: none"><span style="font-style: normal"><span style="font-weight: normal"><span style="background: transparent">the
		BqETH Terms of Service, Privacy Policy, CCPA Notice, and GDPR
		Notice, each as amended by the Company from time to time.</span></span></span></span></font></font></span></font><span style="background: transparent">
		P</span></span></font></font>lease
		visit the BqETH website to review each of these policies.</span></font></font></p>
	</ol>
</ol>
<p >
______________________________________________________________________________</span></font></font></p>
<p >
<br/>

</p>
<p >
<u><b>BY
CLICKING “I AGREE,” I, THE SUBSCRIBER, EXPRESSLY ACKNOWLEDGE,
UNDERSTAND, AND AGREE TO EACH AND EVERY PROVISION OF THIS END-USER
LICENSE AGREEMENT. I HAVE CONSULTED WITH INDEPENDENT COUNSEL PRIOR TO
EXECUTING THIS END-USER LICENSE AGREEMENT OR HAVE BEEN PROVIDED AMPLE
OPPORTUNITY TO DO SO AND EXPRESSLY WAIVE SUCH CONSULT.</b></u></span></font></font></p>
<div title="footer"><p class="western" align="left" style="margin-top: 0.46in; margin-bottom: 0in; border: none; padding: 0in; font-style: normal; font-weight: normal; line-height: 115%; orphans: 2; widows: 2; text-decoration: none">
	<br/>

	</p>
</div>
</body>
</html>`
export default eula;