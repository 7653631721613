import React,{useState} from "react";

//Import Components
import Navbar from "./Navbar/Navbar"
import HeroSection from "./HeroSection/HeroSection"
import AboutUs from "./AboutUs/about-us"
// import Features from "./Features/features"
// import RoadMap from "./RoadMap/road-map"
// import OurTeam from "./Team/our-team"
// import FAQs from "./Faqs/FAQs"
import Footer from "./Footer/footer"
import Intro from "./Learn/intro";
import Features from "./Features/features";
import BqETHFooter from "./Footer/footer";
// import BqETHFAQ from "./Faqs/faq-data";

const CryptoIcoLanding = () => {
  
  //meta title
  document.title="BqETH Digital Inheritance ";

  const [imglight, setimglight] = useState(true);

  window.onscroll = function () {
    scrollFunction();
};

// This is what changes the navbar color and logo when scrolling
const scrollFunction = () => {
    const element = document.getElementById("back-to-top");
    if (element) {
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
            element.style.display = "block";
            setimglight(false)
        } else {
            element.style.display = "none";
            setimglight(true)
        }
    }
};

const toTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
};

  return (
    <React.Fragment>
      {/* import navbar */}
      <Navbar imglight={imglight}/>

      {/* Hero section */}
      <HeroSection />

      {/* mini cards that overlap the Hero and About Section */}
      {/* <CardsMini /> */}

      {/* aboutus */}
      <AboutUs />

      {/* features */}
      <Features />

      {/* roadmap */}
      {/* <RoadMap /> */}

      {/* our team */}
      {/* <OurTeam /> */}

      {/* faqs */}
      {/* <FAQs /> */}
      <Intro />
      {/* blog */}
      {/* <Blog /> */}
      {/* footer */}
      <BqETHFooter />
      <button onClick={() => toTop()} 
        className="btn btn-danger btn-icon landing-back-top" id="back-to-top">
          <i className="ri-arrow-up-line"></i>
          Back to Top
      </button>
    </React.Fragment>
  )
}

export default CryptoIcoLanding
