import React, { useState } from "react";
import { Collapse, Container, NavbarToggler, NavLink } from "reactstrap";
import Scrollspy from "react-scrollspy";
import { Link } from "react-router-dom";

// Import Images
import logodark from "../../../../assets/images/BqETHLogoCroppedBlk.png";
import logolight from "../../../../assets/images/BqETHLogoCroppedWht.png";
// import LanguageDropdown from "Components/Common/LanguageDropdown";

//i18n
import { withTranslation } from "react-i18next";
import withRouter from "Components/Common/withRouter";

const StaticNavbar = (props: any) => {
    const [isOpenMenu, setisOpenMenu] = useState(false);
    const [navClass, ] = useState("");

    const toggle = () => setisOpenMenu(!isOpenMenu);

    return (
        <React.Fragment>
            <nav className={"navbar navbar-expand-lg navigation fixed-top sticky nav-sticky " + navClass} id="navbar">
                <Container>
                    <Link className="navbar-brand" to="/">
                        {props.imglight !== true ? (
                            <img
                                src={logodark}
                                alt="BqETH Logo"
                                height="40"
                                className="card-logo card-logo-dark"
                            />
                        ) : (
                            <img
                                src={logolight}
                                alt="BqETH Logo"
                                height="40"
                                className="card-logo card-logo-light"
                            />
                        )}
                    </Link>

                    <NavbarToggler className="navbar-toggler py-0 fs-20 text-body" onClick={toggle} type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <i className="mdi mdi-menu"></i>
                    </NavbarToggler>

                    <Collapse
                        isOpen={isOpenMenu}
                        className="navbar-collapse"
                        id="navbarSupportedContent"
                    >
                        <Scrollspy
                            offset={-18}
                            items={[
                                "home",
                                "about",
                                "features",
                                "roadmap",
                                "team",
                                "faqs",
                                "contact"
                            ]}
                            currentClassName="active"
                            className="ms-auto navbar-nav nav"
                            id="navbar-example"
                        >
                            <li className="nav-item active">
                                <NavLink href="/">{props.t("Home")}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink href="/#about">{props.t("Start")}</NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink href="#features">{props.t("Features")}</NavLink>
                            </li> */}
                            {/* <li className="nav-item">
                                <NavLink href="/roadmap">{props.t("Roadmap")}</NavLink>
                            </li> */}
                            {/* <li className="nav-item">
                                <NavLink href="/team">{props.t("Team")}</NavLink>
                            </li> */}
                            {/* <li className="nav-item">
                                <NavLink href="/bqeth-pricing">{props.t("Pricing")}</NavLink>
                            </li> */}
                            {/* <li className="nav-item">
                                <NavLink href="/intro">{props.t("Intro")}</NavLink>
                            </li> */}
                            <li className="nav-item">
                                <NavLink href="/learn">{props.t("Learn")}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink href="/faqs">{props.t("FAQ")}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink href="/tpp">{props.t("Professionals")}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink href="/contact">{props.t("Contact Us")}</NavLink>
                            </li>
                        </Scrollspy>

                        {/* <LanguageDropdown/> */}
                        {/* <div className="my-2 ms-lg-2">
                            <Link to="#" className="btn btn-outline-success w-xs">Sign in</Link>
                        </div> */}
                    </Collapse>
                </Container>
            </nav>
        </React.Fragment>
    );
};

export default withRouter(withTranslation()(StaticNavbar));
// export default StaticNavbar;