import React from "react";
import { Col, Container, Row } from "reactstrap";
import DocViewer from "../DocViewer";
import tpa from  '../../../../assets/docs/BqETHTPA';
import BqETHFooter from "../Footer/footer";
import StaticNavbar from "../Navbar/StaticNavBar";

const TPA = () => {

    return (
      <React.Fragment>
        <StaticNavbar imglight={false}/>
        <section className="section bg-white" id="tpa">
          <Container>
            <Row>
              <Col lg="12">
                <div className="text-center mb-5">
                  <DocViewer key="tpa" htmldoc={tpa} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <BqETHFooter/>
      </React.Fragment>
    );
  };
  
  export default TPA;
  