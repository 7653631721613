import React from "react";
import { Col, Container, Row } from "reactstrap";
import DocViewer from "../DocViewer";
import eula from  '../../../../assets/docs/BqETHEULA';
import BqETHFooter from "../Footer/footer";
import StaticNavbar from "../Navbar/StaticNavBar";

const EULA = () => {

    return (
      <React.Fragment>
        <StaticNavbar imglight={false}/>
        <section className="section bg-white" id="eula">
          <Container>
            <Row>
              <Col lg="12">
                <div className="text-center mb-5">
                  <DocViewer key="tos" htmldoc={eula} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <BqETHFooter/>
      </React.Fragment>
    );
  };
  
  export default EULA;
  