import React, { useEffect, useRef, useState } from "react"
import { Container, Row, Col, Modal, Button, ModalHeader, ModalBody, Card, CardBody, ModalFooter, Collapse, Form, InputGroup, Label, FormFeedback, Input, CardTitle, TabPane, Nav, NavLink, TabContent } from "reactstrap";
import ClientCaptcha from 'react-client-captcha';

//Import Breadcrumb
import StaticNavbar from "../Navbar/StaticNavBar";
import DocViewer from "../DocViewer";
import { sha256 } from "ethers/lib/utils";
import tos from  '../../../../assets/docs/BqETHTPPPToS';
import { useFormik } from "formik";
import * as Yup from 'yup';
import { Link } from "react-router-dom";
import { TopicItem, completeFormThunk, findMeThunk, getBqETHFAQThunk, getBqETHPartnerReadyThunk, getBqETHWebinarsThunk, setBqETHPartnerActiveThunk } from "slices/bqeth/thunk";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { createSelector } from "reselect";
import BqETHFaqQuiz from "../Faqs/BqETHFaqQuiz";
import classnames from "classnames"
import FormSupportTPP from "../Forms/FormSupportTPP";
import ZohoSalesIQ from "./SalesIQ";
import PageSense from "pages/Crypto/CryptoIcoLanding/Professionals/PageSense";

const getCurrentDate= () => {
  return new Date().toISOString();
}

const Professionals = () => {

  //meta title
  document.title = "BqETH Trusted Professionals Partner Program";
  const dispatch = useDispatch<any>();
  const [cookies, setCookie] = useCookies();
  const [isTOSModalOpen, setTOSModalOpen] = useState<boolean>(false);
  
  const handleOpenTOS = () => setTOSModalOpen(true);
  const handleCloseTOS = () => setTOSModalOpen(false);
  let utf8Encode = new TextEncoder();

  const [submitted, setSubmitted] = useState(false);
  const [statesOpen, setStatesOpen] = useState(false);

  const uuidCookie: string = useSelector((state: any) => state.bqeth.uuidCookie);
  const acceptedToS: boolean = useSelector((state: any) => state.bqeth.acceptedToS);
  // This is for the result of a call to findMe() to sign-in
  const [acceptsTOS, setAcceptsTOS] = useState(acceptedToS);
  const [userCaptchaInput, setUserCaptchaInput] = useState("");
  const [captchaCode, setCaptchaCode] = useState('');

  useEffect(() => {
    if (cookies['acceptedToS'] != undefined) {
      setAcceptsTOS(cookies['acceptedToS']);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies['userUuid'], cookies['acceptedToS']]);

  useEffect(() => {
    if (acceptedToS) {
      setAcceptsTOS(acceptedToS);
      var expiryDate = new Date();
      expiryDate.setMonth(expiryDate.getMonth() + 3);
      setCookie('acceptedToS', acceptedToS, { path: '/',  expires: expiryDate});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[acceptedToS]);

  useEffect(() => {
    if (uuidCookie) {
      if (uuidCookie != "not found") {
        var expiryDate = new Date();
        expiryDate.setMonth(expiryDate.getMonth() + 3);
        setCookie('userUuid', uuidCookie, { path: '/',  expires: expiryDate});
        setCookie('acceptedToS', acceptedToS, { path: '/',  expires: expiryDate});
        setAcceptsTOS(true);
        set_local_partner_ready(true);
        set_local_answered(10);
        setactiveTab("quiz");
        setShowingToast(true);
      }
      else {
        setShowingFailLogin(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[uuidCookie, acceptedToS]);

  function toggleStates() {
    setStatesOpen(!statesOpen)
  }

  const formik: any = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      firm: "",
      phone: "",
      types: [],
      states: [],
      tosHash: sha256(utf8Encode.encode(tos))
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("This field is required"),
      lastname: Yup.string().required("This field is required"),
      email: Yup.string().required("This field is required"),
      firm: Yup.string().required("This field is required"),
      phone: Yup.string().required("This field is required"),
      types: Yup.array().min(1, "At least one type is required"),
      states: Yup.array().min(1,"At least one Jurisdiction is required"),
    }),

    onSubmit: (values: any) => {
      console.log(JSON.stringify(values, null, 2));
      if (userCaptchaInput === captchaCode) {
        setUserCaptchaInput("");
        setSubmitted(true); // Close it up
        dispatch(completeFormThunk({ 
          user_hash: cookies['userUuid'], 
          date: getCurrentDate(),
          form: 1,
          email: values.email,
          response: values
        }));
        dispatch(getBqETHPartnerReadyThunk({ user_hash: cookies['userUuid']}));
        setAcceptsTOS(true);
        var expiryDate = new Date();
        expiryDate.setMonth(expiryDate.getMonth() + 1);
        setCookie('acceptedToS', true, { path: '/',  expires: expiryDate});
        setCookie('tpp_email', values.email, { path: '/',  expires: expiryDate});
        setTOSModalOpen(false);
      }
      else {
          alert('Captcha Does Not Match');
          setUserCaptchaInput("");
      }
      
    },
  });

  
  const types = ["Estate", "Tax", "Trust", "Family office", "Other"];
  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District Of Columbia",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
    "American Samoa",
    "Guam",
    "Northern Mariana Islands",
    "Puerto Rico",
    "United States Minor Outlying Islands",
    "Virgin Islands"
  ];
  

  const handleTypeChange = (e) => {
    const { checked, name } = e.target;
    if (checked) {
      formik.setFieldValue("types", [...formik.values.types, name]);
    } else {
      formik.setFieldValue(
        "types",
        formik.values.types.filter((v) => v !== name)
      );
    }
  };

  const handleJurisChange = (e) => {
    const { checked, name } = e.target;
    if (checked) {
      formik.setFieldValue("states", [...formik.values.states, name]);
    } else {
      formik.setFieldValue(
        "states",
        formik.values.states.filter((v) => v !== name)
      );
    }
  };

  const signIn: any = useFormik({
    initialValues: {email: "",},
    // validationSchema: Yup.object({}),

    onSubmit: (values: any) => {
      console.log(JSON.stringify(values, null, 2));
      dispatch(findMeThunk({ 
        email: values.email,
      }));
    },
  });

// BqETHProfQuiz  section
const QUESTIONS = 10;

const selectProperties = createSelector(
  (state: any) => state.bqeth.faqs,
  (faqs) => faqs
);

// These 'faqs' are for the Quiz Questions
const faqs = useSelector(selectProperties);

type BucketedItems = { [key: string]: TopicItem[] };
const [bucketedFAQs, setBucketedFAQs] = useState<BucketedItems>();
const [isShowingToast, setShowingToast] = React.useState(false);
const [isShowingFailQuiz, setShowingFailQuiz] = React.useState(false);
const [isShowingFailLogin, setShowingFailLogin] = React.useState(false);

useEffect(() => {
  const timeId = setTimeout(() => {
    // After 3 seconds set the show value to false
    setShowingFailLogin(false)
    dispatch({type: 'clearLogin'});
  }, 10000)

  return () => {
    clearTimeout(timeId)
  }
}, [isShowingFailLogin]);

/* get data */
useEffect(() => {
  dispatch(getBqETHFAQThunk());
  dispatch(getBqETHPartnerReadyThunk({ user_hash: cookies['userUuid']}));
}, [dispatch]);

useEffect(() => {
  // Once faqs have been received from the API, we bucket them by their filters
  function bucketItemsByFiltering(itemlist: TopicItem[]): BucketedItems {
    const bucketedItems: BucketedItems = {
      'General Question': [],
    };
  
    function topic_order( t1: TopicItem, t2: TopicItem ) {
      if ( t1.fields.order == undefined || t2.fields.order == undefined) {
        if ( t2.fields.order != undefined ){
          return -1;
        }
        if ( t1.fields.order != undefined  ){
          return 1;
        }
        return 0;
      }
      if ( t1.fields.order < t2.fields.order ){
        return -1;
      }
      if ( t1.fields.order > t2.fields.order  ){
        return 1;
      }
      return 0;
    }
    var sortedItems = [...itemlist].toSorted( topic_order);

    for(var i=0; i<sortedItems.length; i++) {
      var item: TopicItem = sortedItems[i];
      let filters: string[];

      if (item.fields.Filtering) {
        filters = Array.isArray(item.fields.Filtering) ? item.fields.Filtering : [item.fields.Filtering];
      } else {
        filters = [];
      }
  
      // Remove FAQ as a filter
      if (filters.length === 0 || (filters.length === 1 && filters[0] === 'FAQ')) {
        // Assign to 'General Question' bucket if Filtering is empty or has only 'FAQ'
        bucketedItems['General Question'].push(item);
      } 
      else if (filters.length === 2 && filters.includes('Course') && filters.includes('FAQ')) {
        bucketedItems['General Question'].push(item);
      }
      else {
        // Assign to respective buckets based on Filtering values
        for (var j=0; j<filters.length; j++) {
          var filter = filters[j];
            if (!bucketedItems[filter]) {
              bucketedItems[filter] = [];
            }
            bucketedItems[filter].push(item);
        }
      }
    };
    // console.log(bucketedItems);

    // Remove the FAQ and Course Keys from the FAQs
    delete bucketedItems['General Question'];
    delete bucketedItems['Course'];
    delete bucketedItems['FAQ'];
    delete bucketedItems['Advisor'];
    delete bucketedItems['Process'];
    delete bucketedItems['Wallet'];
    delete bucketedItems['Manual'];
    delete bucketedItems['What If?'];
  
    return bucketedItems;
  }

  if (faqs) {
    setBucketedFAQs(bucketItemsByFiltering(faqs));
  }
}, [faqs]);

const partner_ready: boolean = useSelector((state: any) => state.bqeth.partner_ready);
const answered: number = useSelector((state: any) => state.bqeth.answered);
const [local_partner_ready, set_local_partner_ready] = useState(partner_ready);
const [local_answered, set_local_answered] = useState(answered);

useEffect(() => {
  set_local_partner_ready(partner_ready);
  set_local_answered(answered);
  if (partner_ready && answered == QUESTIONS) {
    setShowingToast(true);
    // Notify the CRM that the user passed the quiz
    dispatch(setBqETHPartnerActiveThunk({ tpp_email: cookies['tpp_email']}));
  }
  else if (!partner_ready && answered == QUESTIONS) {
    // They faile the test
    setShowingFailQuiz(true);
  }
},[partner_ready, answered]);

const [activeTab, setactiveTab] = useState("how_it_works")

const webinars = useSelector((state: any) => state.bqeth.webinars);
  const [local_webinars, set_local_webinars] = useState(webinars);
  useEffect(() => {
    set_local_webinars(webinars)
  },[webinars]);

  /* get data */
  useEffect(() => {
    dispatch(getBqETHWebinarsThunk());
  }, [dispatch]);

  return (
    <React.Fragment>
      <StaticNavbar imglight={false}/>
      <div className="page-content">
        <Container fluid>
        <Row>
          <Col lg="2" sm="4">
            <Nav pills className="flex-column">
              {/* Create the Bucket Navigations Links  */}
              <NavLink
                key="how_it_works"
                className={classnames({ active: activeTab === `how_it_works` })}
                onClick={() => { setactiveTab(`how_it_works`);}}>
                <p className="font-weight-bold mb-0">How it works</p>
              </NavLink>
              {(!acceptsTOS) ? 
              <NavLink
                key="signup"
                className={classnames({ active: activeTab === `signup` })}
                onClick={() => { setactiveTab(`signup`);}}>
                <p className="font-weight-bold mb-0">Sign-up</p>
              </NavLink>: null }
              {(!acceptsTOS) ? 
              <NavLink
                key="login"
                className={classnames({ active: activeTab === `login` })}
                onClick={() => { setactiveTab(`login`);}}>
                <p className="font-weight-bold mb-0">Log-in</p>
              </NavLink>: null }
              {acceptsTOS ? 
              <NavLink
                key="summary"
                className={classnames({ active: activeTab === `summary` })}
                onClick={() => { setactiveTab(`summary`);}}>
                <p className="font-weight-bold mb-0">Quick Summary</p>
              </NavLink>: null }
              {acceptsTOS? 
              <NavLink
                key="quiz"
                className={classnames({ active: activeTab === `quiz` })}
                onClick={() => { setactiveTab(`quiz`);}}>
                <p className="font-weight-bold mb-0">Quiz</p>
              </NavLink>: null}
              {acceptsTOS && local_partner_ready ?
              <NavLink
                key="webinars"
                className={classnames({ active: activeTab === `webinars` })}
                onClick={() => { setactiveTab(`webinars`);}}>
                <p className="font-weight-bold mb-0">Webinars</p>
              </NavLink>: null }
              {acceptsTOS && local_partner_ready ?
              <NavLink
                key="resources"
                className={classnames({ active: activeTab === `resources` })}
                onClick={() => { setactiveTab(`resources`);}}>
                <p className="font-weight-bold mb-0">Resources</p>
              </NavLink>: null}
              {acceptsTOS && local_partner_ready ?
              <NavLink
                key="referrals"
                className={classnames({ active: activeTab === `referrals` })}
                onClick={() => { setactiveTab(`referrals`);}}>
                <p className="font-weight-bold mb-0">Your Referrals</p>
              </NavLink>: null }
              {acceptsTOS && local_partner_ready ?
              <NavLink
                key="support"
                className={classnames({ active: activeTab === `support` })}
                onClick={() => { setactiveTab(`support`);}}>
                <p className="font-weight-bold mb-0">Support</p>
              </NavLink>: null }
              {acceptsTOS && local_partner_ready ?
              <NavLink
                key="consulting"
                className={classnames({ active: activeTab === `consulting` })}
                onClick={() => { setactiveTab(`consulting`);}}>
                <p className="font-weight-bold mb-0">Consulting</p>
              </NavLink>: null }
            </Nav>
          </Col>
          <Col lg={9}>
            <Card>
              <CardBody>
                <TabContent activeTab={activeTab}>
                  <TabPane key="how_it_works" tabId="how_it_works">
                    <div className="text-justify mb-5" >
                    <h3>BqETH Trusted Professionals Partner Program (TPP)</h3>
                    <p className="text-muted"><span className="text-muted">Thank you for your interest in BqETH. We believe there is no better tool for planning and 
                    protecting the the transfer of Digital Assets in the world, and we stand ready to back that up. 
                    By gaining knowledge of this important and rapidly expanding area of expertise, your practice will benefit, 
                    and your clients will be better served. &nbsp;</span></p>
                    <p className="text-muted"><span className="text-muted"></span></p>
                    <p className="text-muted"><span className="text-muted">To support you, we offer no-cost training and the opportunity to become a BqETH Trusted Professional. 
                    As the general public becomes aware of the need to protect their Digital Assets, they will seek out knowledgeable 
                    professionals to guide them.</span></p>
                    <p className="text-muted"><span className="text-muted"></span></p>
                    <p className="text-muted"><span className="text-muted">In the end, we are confident that you will feel comfortable recommending BqETH as part of 
                    your Estate Planning recommendations.</span></p>
                    <h3 className="text-muted" id="h.lefnp51rq4q"><span>HOW IT WORKS</span><span className="text-muted">&nbsp;</span></h3>
                    <ul className="ul arrow">
                      <li><span className="text-muted">Click on the Sign-up menu on the left. This will prompt you to:</span></li>
                      <li className={"mdi mdi-arrow-right"}><span className="text-muted">Review our Agreement</span></li>
                      <li className={"mdi mdi-arrow-right"}><span className="text-muted">Complete the sign-up form and submit</span></li>
                      <li className={"mdi mdi-arrow-right"}><span className="text-muted">Review the short educational summary and take the short quiz</span></li>
                      <br></br>
                      <li><span className="text-muted">Upon successful completion</span></li>
                        <ul>
                          <li className={"mdi"}><span className="text-muted">You receive referrals from our visitors</span></li>
                          <li className={"mdi"}><span className="text-muted">You have access to our webinars and eBooks, as well as the full Learn course and FAQ</span></li>
                          <li className={"mdi"}><span className="text-muted">We send you a survey no more than twice a year to see how we are collectively doing</span></li>
                        </ul>
                      <div className={`mb-5 alert alert-bqeth-teal`}>
                      <span className="text-muted">Your next step is to review the Quick Summary and complete the Quiz.</span><br></br>
                      <span className="text-muted">If you prefer to complete your initial training (summary and Quiz) at no cost with an instructor,</span><br></br>
                      <span className="text-muted">sign up for the T100- Trusted Partner Program Certification (1 hr) <a href="/webinars"> http://bqeth.com/webinars </a></span>
                        </div> 
                      </ul>
                    </div>
                  </TabPane>
                  <TabPane key="signup" tabId="signup">
                    {(!acceptsTOS) ? 
                      <Col lg={9}>
                        <p>If you would like to participate, click the button below to begin. You will be able to review our Terms of Service and set up your account. There are no costs of membership in our Program.</p>
                      <Button className="bqbutton1"  onClick={handleOpenTOS} >
                        TPP Terms of Service and Application
                      </Button>
                      <br></br>
                      </Col>: 
                        <Col lg="12">
                        <div className={`text-center alert alert-bqeth-teal`}>
                          <h4>Thank you and welcome to our Partner Program.</h4>
                        </div>
                        <div className={`mb-5 alert alert-bqeth-teal`}>
                            <span className="text-muted">Your next step is to review the Quick Summary and complete the Quiz.</span><br></br>
                            <span className="text-muted">If you prefer to complete your initial training (summary and Quiz) at no cost with an instructor,</span><br></br>
                            <span className="text-muted">sign up for the T100- Trusted Partner Program Certification (1 hr) <a href="/webinars"> http://bqeth.com/webinars </a></span>
                        </div> 
                      </Col>
                      }
                  </TabPane>
                  <TabPane key="login" tabId="login">
                    {(!acceptsTOS) ? 
                      <Col lg={9}>
                        <div>
                          <br></br>
                          <span className="text-muted">If you have previously signed-up, enter your email address below: </span>
                          <br></br><br></br>
                          <Form className="row gy-2 gx-3 align-items-center" onSubmit={signIn.handleSubmit}>
                            <Row className="mb-4">
                              {/* <Label className="form-check-label" htmlFor="autoSizingEmailInput">Your Email</Label> */}
                              <Col sm={9}>
                              <InputGroup>
                                <div className="input-group-text">@</div>
                                <input
                                  type="email"
                                  name="email"
                                  className="form-control"
                                  id="autoSizingEmailInput"
                                  placeholder="email"
                                  value={signIn.values.email}
                                  onChange={signIn.handleChange}
                                  onBlur={signIn.handleBlur} />
                              </InputGroup>
                              {
                              signIn.errors.email && signIn.touched.email ? (
                                <span className="text-danger">{formik.errors.email}</span>
                              ) : null
                              }
                              <Button type="submit" className="btn btn-primary w-md">Log-in</Button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </Col>: <div><br></br>Thank you and welcome back. </div>
                      }
                      {isShowingFailLogin? 
                            <Col lg="12">
                              <div className={`text-center alert alert-bqeth-teal`}>
                                <h4>Sorry, the email address you entered was not found, please try again.</h4>
                              </div>
                              <div className={`mb-5 alert alert-bqeth-teal`}>
                                  <span className="text-muted">If you are new here, click the Sign-Up button.</span><br></br>
                             </div> 
                            </Col>
                        : null }
                  </TabPane>
                  <TabPane key="summary" tabId="summary">
                      {(acceptsTOS) ? 
                        <Row className="justify-content-center">
                          <Col lg={9}>
                          <div className="text-justify mb-5" >
                            <h3 className="text-muted" id="h.giist7wsjrea">
                              <span className="text-muted">Summary of BqETH and Digital Inheritance</span></h3>
                            <br></br>
                            <span> If you would like to see a video version of this section to watch or listen to, click 
                              <a href="https://workdrive.zohoexternal.com/external/116b6fcd76325a79aea8792fbd3f02ef2345b2aff68f5bf3d3293ba2c29485c3" target="_blank"  >here</a></span>
                            <br></br>
                            <ul className="ul arrow">
                              <li className={"mdi mdi-arrow-right"}><span className="text-muted">BqETH is a</span>
                              <span className="text-muted">&nbsp;</span>
                              <span className="text-muted">stylized</span>
                              <span className="text-muted">&nbsp;version of the word &ldquo;bequeath,&rdquo; a familiar idea in Estate Planning. &nbsp;ETH is a nod to one 
                              of the top digital assets.</span>
                              </li>
                              <li className={"mdi mdi-arrow-right"}><span className="text-muted">Digital Assets (Cryptos, NFTs, other documents of ownership or rights) are 
                              controlled and transferred with secrets: passwords, &nbsp;phrases or &nbsp;lists of words</span></li>
                              <li className={"mdi mdi-arrow-right"}><span className="text-muted">These secrets may be the only way to recover a decedent&rsquo;&rsquo;s assets. 
                              &nbsp;Many &nbsp;millions of dollars worth have already been lost.</span></li>
                              <li className={"mdi mdi-arrow-right"}><span className="text-muted">Physical means of storage (paper, metal cylinders, safes) are subject to 
                              penetration or loss by &nbsp;destruction, forgetfulness, theft, greed, or coercion.</span></li>
                              <li className={"mdi mdi-arrow-right"}><span className="text-muted">Most digital methods used today suffer from reliance on Trust 
                              (forgetfulness, betrayal, greed, coercion) in:.</span>
                                <ul>
                                  <li className={"mdi"}><span className="text-muted">People </span></li>
                                  <li className={"mdi"}><span className="text-muted">Organizations</span></li>
                                  <li className={"mdi"}><span className="text-muted">Governments</span></li>
                                </ul>
                              </li>
                            </ul>
                              <ul className="ul arrow">
                                <li className={"mdi mdi-arrow-right"}><span className="text-muted">BqETH is the only solution for protecting digital assets that does not require:</span>
                            
                                  <ul>
                                      <li className={"mdi"}><span className="text-muted">Trust in any person or institution</span></li>
                                      <li className={"mdi"}><span className="text-muted">Location of any physical servers or storage devices</span></li>
                                      <li className={"mdi"}><span className="text-muted">Knowledge of the client or the recipient</span></li>
                                      <li className={"mdi"}><span className="text-muted">Custody or handling of the assets</span></li>
                                      <li className={"mdi"}><span className="text-muted">Knowledge of the content or structure of the secret</span></li>
                                    </ul>
                                </li>
                                </ul>
                              <ul className="ul arrow">
                                <li className={"mdi mdi-arrow-right"}>
                                <span className="text-muted">The Client accesses BqETH by computer or smart phone and identifies himself with a Web3 
                                (Ethereum-compatible) wallet, like MetaMask</span></li>
                                <li className={"mdi mdi-arrow-right"}><span className="text-muted">The Client sets a timer, called an Hourglass, that must be renewed 
                                (or flipped) to confirm that the Client is still alive.</span></li>
                                <li className={"mdi mdi-arrow-right"}>
                                  <span className="text-muted">The Client can enter the secret as several pages of text and/or by attaching any kind of file. 
                                  Your support in what is stored is crucial.</span></li>
                                <li className={"mdi mdi-arrow-right"}><span className="text-muted">The Client sets up a way for us to remind him of the Hourglass 
                                  ending (e.g., email), and where to send the secret (also email).</span></li>
                                <li className={"mdi mdi-arrow-right"}><span className="text-muted">BqETH secures the secret using a mathematical time-lock puzzle, 
                                  like a time lock on a bank safe. &nbsp;The puzzle is solved by anonymous participants who compete for a reward 
                                  offered by the Client. The puzzle requires a fixed amount of time solve.</span></li>
                                <li className={"mdi mdi-arrow-right"}><span className="text-muted">The Client pays for our notification and delivery service, 
                                  as well as the reward, using a digital currency called POL (formerly MATIC), which can be purchased in our app or an any 
                                  other exchange (e.g., Coinbase).</span></li>
                                <li className={"mdi mdi-arrow-right"}>
                                      <span className="text-muted">BqETH uses several unaligned, anonymously organized virtual systems to protect the secret 
                                      from being revealed before the client&rsquo;s death.</span></li>
                                <li className={"mdi mdi-arrow-right"}><span className="text-muted">The client can log in and renew the hourglass at any time, 
                                      confirming life. If the Hourglass runs out, we use this as proof of death.</span></li>
                                <li className={"mdi mdi-arrow-right"}><span className="text-muted">Once the puzzle is solved, and if the Client has not renewed 
                                      the Hourglass, the secret is decrypted by another anonymous system and then sent&mdash;by another automated 
                                      system&mdash; to the Client&rsquo;s intended recipients. These recipients can also benefit from your counsel. &nbsp;
                                      In case of delivery failure, the secret is indelibly written to a searchable blockchain.</span></li>
                                <li className={"mdi mdi-arrow-right"}><span className="text-muted">The cost is currently around $500 USD for twelve months. 
                                      The price will adjust based on market conditions of computing power.</span></li>
                              </ul>
                              <p className="text-muted">
                                <span className="text-muted"></span></p>
                                <p className="text-muted"><span className="text-muted">Now that you have reviewed BqETH basics, we would like you to try a short quiz.</span></p>
                              <p className="text-muted">
                                <span className="text-muted"></span></p>
                                <p className="text-muted"><span className="text-muted">
                                The information we covered in the Summary is everything you need to know to complete it successfully, but 
                                only touches on the very most key points of BqETH technology and the subject of Digital assets in general. 
                                BqETH Trusted Professionals have access to no-cost training &nbsp;as well as Client referrals.&nbsp;
                                We strongly suggest you take advantage of our webinars and ebooks, as well as the Learn and FAQ sections 
                                of this site. The more knowledge you acquire will improve your ability to serve your Clients as well as 
                                confidently pursue new Clients looking for a secure solution.</span></p>
                              <p className="text-muted"><span className="text-muted">When you are ready, click on the Quiz button to begin. 
                                Please answer all ten questions. Your membership will become Active and eligible for referrals when a 
                                minimum number of questions have been answered correctly.
                                You can retake the Quiz every 24 hours. We will notify you with the results.</span></p>
                            </div>
                          </Col>
                        </Row>
                      : null }
                  </TabPane>
                  <TabPane key="quiz" tabId="quiz">
                        {/* <BqETHProfQuiz/> */}
                      <section className="section" id="faqs">
                        {local_answered != undefined && local_answered < 10 ? 
                        <Container>
                        <Row className="justify-content-center">
                            <Col lg="12">
                              <div className="text-center mb-5">
                                <h4>Advisor Quiz</h4>
                              </div>
                            </Col>
                        </Row>
                        <Row> 
                          <Col lg="12">
                            <div className="vertical-nav">
                                  <Card>
                                    <CardBody>
                                        {/* This iterates over the bucketed list of topics (there's only 1) */}
                                        {bucketedFAQs? Object.values(bucketedFAQs).map((bucket, index) => (
                                          <TabPane key={index} tabId={`${index + 1}`}>
                                            {/* Create the Quiz Entries  */}
                                            <BqETHFaqQuiz entries={bucket.map(item => ({ 
                                              topic: item.fields.topic_id, 
                                              order: item.fields.order,
                                              question: item.fields.FullTitle, 
                                              answer: item.fields.Component,
                                              allowQuiz: true 
                                              }))} />
                                          </TabPane>
                                        )):null}
                                      </CardBody>
                                    </Card>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                        : 
                        <Container>
                        {isShowingToast? 
                            <Col lg="12">
                              <div className={`text-center alert alert-bqeth-teal`}>
                                <h4>Congratulations, you passed the Trusted Partner Quiz.</h4>
                              </div>
                              <div className={`mb-5 alert alert-bqeth-teal`}>
                                  <span className="text-muted">Welcome to the Trusted Professional Program.</span><br></br>
                                  <span className="text-muted">The buttons to the left will provide you with training, materials, and support. </span><br></br>
                                  <span className="text-muted">As they are assigned, referrals that we have sent you will be available for your review. </span>
                              </div> 
                            </Col>
                        : null }
                        {isShowingFailQuiz? 
                            <Col lg="12">
                              <div className={`text-center alert alert-bqeth-teal`}>
                                <h4>Unfortunately, you did not pass the quiz.</h4>
                              </div>
                              <div className={`mb-5 alert alert-bqeth-teal`}>
                                  <span className="text-muted">Don't panic! You can retry the quiz in 24 hours.</span><br></br>
                                  <span className="text-muted">Some or all of the questions may be different.  All the correct answers you need are in the quick summary,<br></br> so you may want to review that.</span><br></br>
                                  <span className="text-muted">If you prefer to complete your initial training (summary and Quiz) at no cost with an instructor,</span><br></br>
                                  <span className="text-muted">sign up for the T100- Trusted Partner Program Certification (1 hr) <a href="/webinars"> http://bqeth.com/webinars </a></span>
                              </div> 
                            </Col>
                        : null }
                        </Container>
                        }
                      </section>
                  </TabPane>
                  <TabPane key="webinars" tabId="webinars">
                    <Container>
                      <Row className="justify-content-center">
                          <Col lg="12">
                            <div className="text-center mb-5">
                              <h4>Upcoming Webinars</h4>
                            </div>
                          </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <div className="vertical-nav">
                            {local_webinars ? local_webinars.map((web, index) => (
                                <Card>
                                  <CardBody>
                                  <div className="mb-3">
                                    <i className="mdi mdi-presentation-play h2 text-bqeth-teal" />
                                  </div>
                                  <p className="text-muted mb-0">
                                    {web.startTime}
                                  </p>
                                  <h5>{web.topic}</h5>
                                  <p className="text-muted mb-0">
                                    {web.agenda}
                                  </p>
                                </CardBody>
                                <div className="card-footer bg-transparent border-top text-center">
                                  <Link to={web.registrationLink} className="text-primary">
                                    Register
                                  </Link>
                                </div>
                                  </Card>                              
                            )):null} 
                            </div>
                          </Col>
                        </Row>
                    </Container>
                    <Container>
                    <Row className="justify-content-center">
                          <Col lg="12">
                            <div className="text-center mb-5">
                              <h4>Recorded Webinars</h4>
                            </div>
                          </Col>
                      </Row>
                      <Row>
                      <Col lg={8} md={4} sm={2}>
                          <Card style={{width:"60%"}}>
                            <iframe 
                            src="https://workdrive.zohoexternal.com/embed/tkw317766bbe81c3545438ec1271f96882b3d?toolbar=false&layout=grid&appearance=light&themecolor=green"  
                            // scrolling="no" frameborder="0" allowfullscreen=true 
                            width="800" height="520" title="Embed code" >
                            </iframe>
                          </Card>
                        </Col>
                        </Row>
                    </Container>
                  </TabPane>
                  <TabPane key="resources" tabId="resources"> 
                      <Col lg={8} md={4} sm={2}>
                          <Card style={{width:"60%"}}>
                            <iframe 
                            src="https://workdrive.zohoexternal.com/embed/s0mx89bfa23adbe22409a97b24edb2c3cd563?toolbar=false&layout=grid&appearance=light&themecolor=green" 
                            // scrolling="no" frameborder="0" allowfullscreen=true 
                            width="800" height="520" title="Embed code" >
                            </iframe>
                          </Card>
                        </Col>
                  </TabPane> 
                  <TabPane key="referrals" tabId="referrals"> 
                      <Col lg={8} md={4} sm={2}>
                          <Card style={{width:"60%"}}>
                            <CardBody>
                              <h4>No Referrals found</h4>
                            </CardBody>
                          </Card>
                        </Col>
                  </TabPane> 
                  <TabPane key="support" tabId="support"> 
                    <Row className="justify-content-center">
                      <Col lg={8} md={4} sm={2}>
                        <div className={`mb-5 alert alert-bqeth-teal`}>
                          <span className="text-muted">For Trusted Partner Program questions, submit this form.</span>
                        </div>
                            <FormSupportTPP/>
                      </Col>
                    </Row> 
                  </TabPane> 
                  <TabPane key="consulting" tabId="consulting">
                    <Container>
                      <Row className="justify-content-center">
                          <Col lg="12">
                            <div className="text-center mb-5">
                              <h4>Consulting</h4>
                              <div className={`mb-5 alert alert-bqeth-teal`}>
                                  <span className="text-muted">Membership in the BqETH Trusted Partner Program is always at no cost to you.</span><br></br>
                                  <span className="text-muted">However, we do offer paid consulting for you, or you and your Clients. Please note pricing in the options below.</span>
                                  <span>For new TPP members, we are offering 15 minute tag ups at no cost. See "Trusted Professionals Program" option below.</span>
                              </div>  
                              <Col lg="12">
                                <iframe width='100%' height='585px' title="booking_widget" 
                                src='https://booking.bqeth.com/portal-embed#/consulting'
                                style={{border: "0"}} allowFullScreen > 
                                </iframe>
                              </Col>
                            </div>
                          </Col>
                      </Row>
                    </Container>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
        </Container>
        <Modal isOpen={isTOSModalOpen} id="tosModal" autoFocus={true} size="lg" backdrop={true} centered toggle={handleCloseTOS}>
            <ModalHeader toggle={handleCloseTOS}>Before proceeding, please agree to the following:</ModalHeader>
            <ModalBody>
              <Card className="border plan-box " styles={[{ marginTop: 16 }]}>
                <CardBody className="p-5">
                {isTOSModalOpen? 
                  <DocViewer key="tos" htmldoc={tos} />
                  : null }
                </CardBody>
                <div className="modal-body" id="signup" >
                <h5>BqETH Trusted Advisor Program Signup</h5>
                <p>
                  We will contact you and use the information you provide us below in accordance with BqETH Trusted Advisor Program documents.
                </p>
                <Card>
                  <CardBody> {/* ANY CHANGES MADE HERE PROBABLY NEED TO ALSO CHANGE IN FormPartner.tsx */ }
                    <CardTitle className="h5 mb-4">Please provide the following:</CardTitle>
                    {submitted ? <div>Submitted. Thank you.</div>: 
                    <Form className="row gy-2 gx-3 align-items-center" onSubmit={formik.handleSubmit}>
                      <Row className="mb-4" >
                        <Label className="form-check-label" htmlFor="autoSizingFNameInput">First Name</Label>
                        <Col sm={9}>
                        <Input type="text" name="firstname" className="form-control" id="autoSizingFNameInput" placeholder="Jane"
                          value={formik.values.firstname}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched.firstname && formik.errors.firstname ? true : false
                          } />
                        {
                          formik.errors.firstname && formik.touched.firstname ? (
                            <FormFeedback type="invalid">{formik.errors.firstname}</FormFeedback>
                          ) : null
                        }                    
                        </Col>
                      </Row>
                      <Row className="mb-4" >
                        <Label className="form-check-label" htmlFor="autoSizingLNameInput">Last Name</Label>
                        <Col sm={9}>
                        <Input type="text" name="lastname" className="form-control" id="autoSizingLNameInput" placeholder="Doe"
                          value={formik.values.lastname}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched.lastname && formik.errors.lastname ? true : false
                          } />
                        {
                          formik.errors.lastname && formik.touched.lastname ? (
                            <FormFeedback type="invalid">{formik.errors.lastname}</FormFeedback>
                          ) : null
                        }                    
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Label className="form-check-label" htmlFor="autoSizingEmailInput">Your Email</Label>
                        <Col sm={9}>
                        <InputGroup>
                          <div className="input-group-text">@</div>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            id="autoSizingEmailInput"
                            placeholder="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                        </InputGroup>
                        {
                          formik.errors.email && formik.touched.email ? (
                            <span className="text-danger">{formik.errors.email}</span>
                          ) : null
                        }
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Label className="form-check-label" htmlFor="autoSizingFirmInput">Your Firm</Label>
                        <Col sm={9}>
                        <Input type="text" name="firm" className="form-control" id="autoSizingFirmInput" placeholder=""
                          value={formik.values.firm}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          invalid={
                            formik.touched.firm && formik.errors.firm ? true : false
                          } />
                        {
                          formik.errors.firm && formik.touched.firm ? (
                            <FormFeedback type="invalid">{formik.errors.firm}</FormFeedback>
                          ) : null
                        }
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Label className="form-check-label" htmlFor="autoSizingInputGroup">Your Phone</Label>
                        <Col sm={9}>
                        <InputGroup>
                          <div className="input-group-text">#</div>
                          <input
                            type="tel"
                            name="phone"
                            className="form-control"
                            id="autoSizingInputGroup"
                            placeholder="phone"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur} />
                        </InputGroup>
                        {
                          formik.errors.phone && formik.touched.phone ? (
                            <span className="text-danger">{formik.errors.phone}</span>
                          ) : null
                        }
                        </Col>
                      </Row>

                      <Row className="mb-4">
                        <label className="form-check-label" >Practice type</label>
                        {types.map((type) => (
                          <div key={type}>
                            <input className="form-check-input"
                              id={type}
                              type="checkbox"
                              name={type}
                              checked={formik.values.types.includes(type)}
                              onChange={handleTypeChange}
                            />
                            <label className="form-check-label" htmlFor={type}>{type}</label>
                          </div>
                        ))}
                        {
                        formik.errors.types && formik.touched.types ? (
                          <span className="text-danger">{formik.errors.types}</span>
                        ) : null
                        }
                      </Row>

                      <Row className="mb-4" >
                        <div id="gen-ques-accordion" className="accordion custom-accordion">
                        <Link
                                to="#"
                                className="accordion-list"
                                onClick={() => {toggleStates()}}
                                style={{ cursor: "pointer" }}
                              >
                                <label className="form-check-label" htmlFor="stateSelect">Select Licensed Jurisdiction(s)</label>
                                <i style={{color:"darkgray"}}
                                  className={
                                    statesOpen
                                      ? "mdi mdi-minus accor-plus-icon"
                                      : "mdi mdi-plus accor-plus-icon"
                                  }
                                />
                        </Link>
                        </div>
                        <Collapse isOpen={statesOpen}>
                        {states.map((juris) => (
                          <div key={juris}>
                            <input className="form-check-input"
                              id={juris}
                              type="checkbox"
                              name={juris}
                              checked={formik.values.states.includes(juris)}
                              onChange={handleJurisChange}
                            />
                            <label className="form-check-label" htmlFor={juris}>{juris}</label>
                          </div>
                        ))}
                        </Collapse>
                        {
                          formik.errors.states && formik.touched.states ? (
                            <span className="text-danger">{formik.errors.states}</span>
                          ) : null
                        }
                      </Row>
                      <Row className="mb-4" >
                        <div className="col mt-3">
                            <ClientCaptcha backgroundColor="#F2F2F2" fontSize="18" fontFamily="DejaVu Sans Mono" charsCount='5' captchaCode={setCaptchaCode} />
                            {/* <div className="text">Current Captcha Code: <strong>{captchaCode}</strong></div> */} 
                        </div>
                        <div className="col mt-3">
                            <div>
                              <p>Attention: captcha is case sensitive.</p>
                              <input style={{fontFamily:"DejaVu Sans Mono"}}
                                      placeholder="Enter Captcha Value" 
                                      id="user_captcha_input" 
                                      name="user_captcha_input" 
                                      value={userCaptchaInput} 
                                      type="text"
                                      onChange={(event) => {
                                          setUserCaptchaInput(event.target.value)
                                        }}
                                      />
                              </div>
                        </div>
                      </Row>

                      <ModalFooter>
                        <Button type="submit" className="btn btn-primary w-md">I Agree & Submit</Button>
                        <Button color="secondary" onClick={handleCloseTOS}>Close</Button>
                      </ModalFooter>
                    </Form>
                    }
                  </CardBody>
                </Card>
              </div>
              </Card>
            </ModalBody>
        </Modal>
        <ZohoSalesIQ />
        <PageSense />
      </div>
    </React.Fragment>
  )
}
export default Professionals
