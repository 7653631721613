import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Input, FormFeedback, InputGroup } from "reactstrap";
import ClientCaptcha from 'react-client-captcha';
import { useFormik } from "formik";
import * as Yup from 'yup';
import { completeFormThunk } from "slices/bqeth/thunk";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
const getCurrentDate= () => {
  return new Date().toISOString();
}

const FormRegister = () => {

  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch<any>();
  const [cookies, ] = useCookies(['userUuid']);
  const [userCaptchaInput, setUserCaptchaInput] = useState("");
  const [captchaCode, setCaptchaCode] = useState('');

  const formik: any = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: Yup.object({
      email: Yup.string().required("This field is required"),
    }),

    onSubmit: (values: any) => {
      console.log(JSON.stringify(values, null, 2));
      if (userCaptchaInput === captchaCode) {
        setSubmitted(true); // Close it up
        dispatch(completeFormThunk({ 
          user_hash: cookies['userUuid'], 
          date: getCurrentDate(),
          form: 4,
          email: values.email,
          response: values
        }));
      }
      else {
          alert('Captcha Does Not Match');
          setUserCaptchaInput("");
      }
    },
  });

  return (
    <React.Fragment>
            <div className="modal-body">
              <h5>Don't lose your spot in the course !</h5>
              <p>
                We strive not to track anyone. But if you give us your email you'll be able to save your spot in the course. 
              </p>
              <Card>
                <CardBody>
                  <CardTitle className="h5 mb-4">Please provide the following:</CardTitle>
                  {submitted ? <div>Submitted. Thank you.</div>: 
                  <Form className="row gy-2 gx-3 align-items-center" onSubmit={formik.handleSubmit}>
                    <Row className="mb-4" >
                      <Label className="form-check-label" htmlFor="autoSizingInputGroup">Your Email</Label>
                      <Col sm={9}>
                      <InputGroup>
                        <div className="input-group-text">@</div>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          id="autoSizingInputGroup"
                          placeholder="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur} />
                      </InputGroup>
                      {
                        formik.errors.email && formik.touched.email ? (
                          <span className="text-danger">{formik.errors.email}</span>
                        ) : null
                      }
                      </Col>
                    </Row>
                    <Row className="mb-4" >
                      <div className="col mt-3">
                      <ClientCaptcha backgroundColor="#F2F2F2" fontSize="18" fontFamily="DejaVu Sans Mono" charsCount='5' captchaCode={setCaptchaCode} />
                      </div>
                      <div className="col mt-3">
                          <div>
                                <p>Attention: captcha is case sensitive.</p> 
                                <input style={{fontFamily:"DejaVu Sans Mono"}}
                                    placeholder="Enter Captcha Value" 
                                    id="user_captcha_input" 
                                    name="user_captcha_input" 
                                    value={userCaptchaInput} 
                                    type="text"
                                    onChange={(event) => {
                                        setUserCaptchaInput(event.target.value)
                                      }}
                                    />
                            </div>
                      </div>
                    </Row>
                    <div className="col-sm-auto">
                      <button type="submit" className="btn btn-primary w-md"
                      >Submit</button>
                    </div>
                  </Form>
                  }
                </CardBody>
              </Card>
            </div>
    </React.Fragment>
  )
}
export default FormRegister;
