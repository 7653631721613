import TopicLinkComponent from "./LinkComponent";
import missing from "../../../../assets/images/error-img.png";
import { hashCode } from "common/data/crypto";

  // Called by TopicComponent to render embedded images.
  const ImageComponent = ({ content }) => {

    const images = require.context("../../../../assets/images/topics", false);
    const renderImages = () => {
      if (content) {
        const regex = /(\[[a-zA-Z0-9.]+.png[,\d+(?|%|:\d+)]*\]|\[[a-zA-Z0-9.]+.jpg[,\d+(?|%|:\d+)]*\])/g;
        const parts = content.split(regex);
    
        if (parts) {
          return parts.map((part, index) => {
            if (regex.test(part)) {

              // Dynamically load the image using the images context
              try {
                const imageName = part.match(/[a-zA-Z0-9.]+/)[0];
                const percent = part.match(/\d+%/)? part.match(/\d+%/)[0]: '100%';
                var p1 = part.match(/\d+:/)? part.match(/\d+:/)[0]: undefined;
                var p2 = part.match(/:\d+/)? part.match(/:\d+/)[0]: undefined;
                
                const dynamicImage = images(`./${imageName}`);
                const key = 'IM-'+index;
                if (dynamicImage) {
                  // Figure out if we want to use p1,p2 or percent
                  if (!p1 && !p2 && percent) {
                    p1 = percent;
                    p2 = percent;
                  }
                  else {
                    p1 = p1.replace(/[:]/g, "")
                    p2 = p2.replace(/[:]/g, "")
                  }
                  return (
                    <div className="embedded-image" key={hashCode(imageName)} >
                      {dynamicImage && 
                      <img 
                        src={dynamicImage} 
                        alt={imageName} 
                        width={p1}
                        height={p2}
                        />
                        }
                    </div>
                  )
                }
              }
              catch {
                const key = 'I-'+index;
                return (
                  <div className="embedded-image" >
                    <img key={key} 
                      src={missing} 
                      alt="a depressed guy" 
                      width="20%"
                      height="20%" 
                      />
                  </div>
                )
              }
            } else {
              const key = 'L-'+index;
              return <TopicLinkComponent key={key} content={part} />
            }
          });
        }
      }
    };
  
    return <>{renderImages()}</>;
  };

  export default ImageComponent;