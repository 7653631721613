import React from "react";
import { Container, Row, Col } from "reactstrap";

//Import Components
import FeatureBox from "./feature-box";

//Import images
import feature1pic from "../../../../assets/images/crypto/features-img/img-1.png";
import feature2pic from "../../../../assets/images/crypto/features-img/img-2.png";
import feature3pic from "../../../../assets/images/crypto/features-img/761.jpg";
import feature4pic from "../../../../assets/images/crypto/features-img/978.jpg";
import StaticNavbar from "../Navbar/StaticNavBar";

const Features = () => {
  const features1 = [ // Decentralized, Unstoppable
    { id: 1, desc: "Multiple independent blockchains guarantee decryption of your secret." },
    { id: 2, desc: "Your secret cannot be released until it is time." },
  ];
  const features2 = [ // Flexible, Private
    { id: 1, desc: "From passwords to family revelations, you choose what and to hide and how others will receive it." },
    { id: 2, desc: "From decryption keys, to revelations to family, you chose what to store. " },
  ];
  const features3 = [ // Absolutely Trustless
    { id: 1, desc: "No corporation is immune. No physical location or object is secure. No jurisdiction can be trusted." },
    { id: 2, desc: "Anonymous networks, math, and cryptography is the 21st century solution to security." },
  ];
  const features4 = [ // Safe
    { id: 1, desc: "BqETH does not demand identification from anyone." },
    { id: 2, desc: "We don’t know who you are. We cannot betray you." },
    { id: 3, desc: "You and your loved ones remain anonymous and safe. Your inheritance is secure." },
  ];

  return (
    <React.Fragment>
      <section className="section" id="features">
        <StaticNavbar imglight={false} />
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">Features</div>
                <h4>Key features of BqETH</h4>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center pt-4">
            <Col md="6" sm="8">
              <div>
                <img
                  src={feature4pic}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
            <Col md="5" className="ms-auto">
              {/* featurebox */}
              <FeatureBox
                num="01"
                title="Decentralized, Unstoppable"
                features={features1}
                desc="BqETH secures your secrets with a mathematical time lock and proof of work to open it."
              />
            </Col>
          </Row>

          <Row className="align-items-center mt-5 pt-md-5">
            <Col md="5">
              {/* featurebox */}
              <FeatureBox
                num="02"
                title="Flexible, Non-Custodial"
                features={features2}
                desc="BqETH does not hold custody, have control, or take a percentage of your assets."
              />
            </Col>
            <Col md="6" sm="8" className="ms-md-auto">
              <div className="mt-4 me-md-0">
                <img
                  src={feature2pic}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
          </Row>

          <Row className="align-items-center pt-4">
            <Col md="6" sm="8">
              <div>
                <img
                  src={feature3pic}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
            <Col md="5" className="ms-auto">
              {/* featurebox */}
              <FeatureBox
                num="03"
                title="Trustless, Uncensorable"
                features={features3}
                desc="Sadly, any human can betray you, willingly or not: friends, family, advisors or institution."
              />
            </Col>
          </Row>

          <Row className="align-items-center mt-5 pt-md-5">
            <Col md="5">
              {/* featurebox */}
              <FeatureBox
                num="04"
                title="Safe, Private"
                features={features4}
                desc="BqETH does not know about or could endanger you or your beneficiaries."
              />
            </Col>
            <Col md="6" sm="8" className="ms-md-auto">
              <div className="mt-4 me-md-0">
                <img
                  src={feature1pic}
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </Col>
          </Row>

        </Container>
      </section>
    </React.Fragment>
  );
};

export default Features;
