import React, { useEffect } from 'react';

declare global {
  interface Window {
    $zoho: any;
  }
}
const ZohoSalesIQ: React.FC = () => {
  useEffect(() => {
    // Check if the Zoho SalesIQ object exists and initialize if not
    if (!window.$zoho) {
      window.$zoho = {};
    }
    if (!window.$zoho.salesiq) {
      window.$zoho.salesiq = { ready: function() {} };
    }

    // Create a script element for Zoho SalesIQ
    const script = document.createElement('script');
    script.src = 'https://salesiq.zohopublic.com/widget?wc=siq3dc69257b95299083699d7cb3091e80d';
    script.defer = true;
    script.id = 'zsiqscript';

    // Append the script to the body
    document.body.appendChild(script);

    // Wait for the script to load and then call initialization function
    script.onload = () => {
      // Zoho SalesIQ initialization
      if (window.$zoho && window.$zoho.salesiq) {
        window.$zoho.salesiq.ready(function() {
          // Example to show the chat widget programmatically if needed
          window.$zoho.salesiq.floatwindow.visible('show');
        });
      }
    };

    // Cleanup the script when the component unmounts
    return () => {
      document.getElementById('zsiqscript')?.remove();
    };
  }, []);

  return null; // This component doesn't render anything itself
};

export default ZohoSalesIQ;
