import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, NavLink, CardTitle, CardHeader, Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import { Link } from "react-router-dom";

//Images
import threshold from "../../../../assets/images/clients/threshold.svg";

//swiper
import "../../../../../node_modules/swiper/swiper.scss";
import { DAPP } from "helpers/url_helper";
import FormButtonComponent from "../RenderComponents/FormComponent";

const AboutUs = () => {

  return (
    <React.Fragment>
      <section className="section bg-white" id="about">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <div className="small-title">About us</div>
                <h4>Why BqETH ?</h4>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg="5">
              <div className="text-muted">
                <h4>The blockchain-based solution to the problem of Digital Asset Inheritance</h4>
                <p>
                  What is the point of embracing a new world of wealth protection only to hand it over to the failed schemes of the past? 
                </p>
                <p className="mb-4">
                  Any crypto legacy plan that relies on trust in people, corporations, paper, metal objects or physical locations can and often fails--$100 Billion in the last 15 years.  Don't let your heirs suffer too.
                </p>
                <p>
                  Grounded in the principles of security and privacy, we use only the tools  of mathematics, cryptography and blockchain technology to build an unbreakable Digital Inheritance protocol. 
                  For our clients, deceptively simple to use, and built to last a lifetime.  
                </p>
                {/* <div className="d-flex flex-wrap gap-2">
                  <Link to="/learn" className="btn btn-bqeth-teal">Read More</Link>
                  <button className="btn btn-light"><NavLink href="#intro" >How it works</NavLink></button>
                </div> */}
              </div>
            </Col>

            <Col lg="6" className="ms-auto">
              <Card className="border plan-box" outline color="primary" style={{width:"90%"}}>
                <CardHeader  tag="h3">Get Started</CardHeader>
                <CardBody className="p-4">
                    <Row style={{ paddingLeft: "20px", marginTop: "5px" }}>Learn How to use BqETH</Row>
                    <Row style={{ paddingLeft: "20px", marginTop: "5px" }}>
                      <Link to="/learn" className="text-primary">Self-paced course</Link>
                    </Row>
                    <Row style={{ paddingLeft: "20px", marginTop: "5px" }}>
                      <Link to="/faqs" className="text-primary">FAQs</Link>
                    </Row>
                    <Row style={{ paddingLeft: "20px", marginTop: "5px" }}>Launch the App</Row>
                    <div style={{ paddingLeft: "20px", marginTop: "5px" }}>
                      <a href={`${DAPP}?test_mode=true`} id="launch" target="_blank" rel="noreferrer">Launch App</a>
                    </div>
                    <Row style={{ paddingLeft: "20px", marginTop: "5px" }}>Get Professional Assistance</Row>
                    <div style={{ paddingLeft: "20px", marginTop: "5px" }}>
                      <FormButtonComponent key="F0" content="[F0]" text="Have us find you a Professional Estate advisor" />
                    </div>
                    <div style={{ paddingLeft: "20px", marginTop: "5px" }}>
                      <FormButtonComponent key="F3" content="[F3]" text="Have  us contact your current Estate Advisor" />
                    </div>
                    <div style={{ paddingLeft: "20px", marginTop: "5px" }}>
                      <a href="https://booking.bqeth.com" target="_blank" rel="noreferrer">Schedule a paid consulting session</a>
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <hr className="my-5" />Our Partners
          <Row className="justify-content-center">
            <div className="client-images">
                      <img src={threshold} alt="client-img" className="mx-auto img-fluid d-block" />
                    </div>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default AboutUs;
