import React, { useEffect } from 'react';

interface DocViewerProps {
    htmldoc: string;
}

const DocViewer: React.FC<DocViewerProps> = ({ htmldoc }) => {
      
    return (
        <div style={{ width: '655px' }} 
          dangerouslySetInnerHTML={{ __html: htmldoc }} >
        </div>
    );
};

export default DocViewer;
