import React from "react"
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../../Components/Common/Breadcrumb";
import StaticNavbar from "../Navbar/StaticNavBar";

const Intro = () => {

 //meta title
document.title = "Introduction | BqETH";

  return (
    <React.Fragment>
      <section className="section bg-white" id="intro">

        <Container fluid>
          <Row className="justify-content-center">
            {/* <Col  lg={12} md={8} sm={4}> */}
              <Card className="justify-content-center" style={{width:"60%"}}>
                {/* <CardBody> */}
                  <CardTitle className="justify-content-center" tag="h4">Introduction to BqETH</CardTitle>
                  <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                    {/* <iframe
                      title="intro_youtube"
                      className="embed-responsive-item"
                      src="https://www.youtube.com/embed/nizfDvdl2PU"
                    /> */}
                    <iframe title="intro_rumble" className="rumble" width="640" height="360" 
                      src="https://rumble.com/embed/v4w7x6t/?pub=gyh3d" >
                      </iframe>
                  </div>
                {/* </CardBody> */}
              </Card>
            {/* </Col> */}
          </Row>
        </Container>
        </section>
    </React.Fragment>
  )
}
export default Intro
