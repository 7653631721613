import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle, Form, Label, Input, FormFeedback, InputGroup } from "reactstrap";
import ClientCaptcha from 'react-client-captcha';

import { useFormik } from "formik";
import * as Yup from 'yup';

import { completeFormThunk } from "slices/bqeth/thunk";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
const getCurrentDate= () => {
  return new Date().toISOString();
}

const FormMarketing = () => {

  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch<any>();
  const [cookies, ] = useCookies(['userUuid']);
  const [userCaptchaInput, setUserCaptchaInput] = useState("");
  const [captchaCode, setCaptchaCode] = useState('');

  const formik: any = useFormik({
    initialValues: {
      ur_fname: "",
      ur_lname: "",
      ur_email: "",
      ur_phone: "",
      ur_state: "",

      lw_fname : "",
      lw_lname : "",
      lw_firm: "",
      lw_email: "",
      lw_phone: "",
    },
    validationSchema: Yup.object({
      ur_fname: Yup.string().required("This field is required"),
      ur_lname: Yup.string().required("This field is required"),
      ur_email: Yup.string().required("This field is required"),
      ur_state: Yup.string().required("This field is required"),
      ur_phone: Yup.string().required("This field is required"),

      lw_fname: Yup.string().required("This field is required"),
      lw_lname: Yup.string().required("This field is required"),
      lw_email: Yup.string().required("This field is required"),
      lw_firm: Yup.string().required("This field is required"),
      lw_phone: Yup.string().required("This field is required"),
    }),

    onSubmit: (values: any) => {
      console.log(JSON.stringify(values, null, 2));
      if (userCaptchaInput === captchaCode) {
        setSubmitted(true); // Close it up
        dispatch(completeFormThunk({ 
          user_hash: cookies['userUuid'], 
          date: getCurrentDate(),
          form: 3,
          email: values.ur_email,
          response: values
        }));
      }
      else {
          alert('Captcha Does Not Match');
          setUserCaptchaInput("");
      }
    },
  });

  return (
    <React.Fragment>
            <div className="modal-body">
              <h5>Advisor Referral from BqETH</h5>
              <p>
                We will contact your Advisor and provide your name. 
                We will inform them of your interest in BqETH and offer our assistance.
              </p>
              <Card>
                <CardBody>
                  <CardTitle className="h5 mb-4">Please provide the following:</CardTitle>
                  {submitted ? <div>Submitted. Thank you.</div>: 
                  <Form className="row gy-2 gx-3 align-items-center"  id="marketing" onSubmit={formik.handleSubmit}>
                    <Row className="mb-4" >
                      <Label className="form-check-label" htmlFor="autoSizingFNInput">First Name</Label>
                      <Col sm={9}>
                      <Input type="text" name="ur_fname" className="form-control" id="autoSizingFNInput" placeholder="Jane"
                        value={formik.values.ur_fname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={
                          formik.touched.ur_fname && formik.errors.ur_fname ? true : false
                        } />
                      {
                        formik.errors.ur_fname && formik.touched.ur_fname ? (
                          <FormFeedback type="invalid">{formik.errors.ur_fname}</FormFeedback>
                        ) : null
                      }
                      </Col>
                    </Row>
                    <Row className="mb-4" >
                      <Label className="form-check-label" htmlFor="autoSizingLNInput">Last Name</Label>
                      <Col sm={9}>
                      <Input type="text" name="ur_lname" className="form-control" id="autoSizingLNInput" placeholder="Doe"
                        value={formik.values.ur_lname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={
                          formik.touched.ur_lname && formik.errors.ur_lname ? true : false
                        } />
                      {
                        formik.errors.ur_lname && formik.touched.ur_lname ? (
                          <FormFeedback type="invalid">{formik.errors.ur_lname}</FormFeedback>
                        ) : null
                      }
                      </Col>
                    </Row>
                    <Row className="mb-4" >
                      <Label className="form-check-label" htmlFor="autoSizingInputGroup">Your Email</Label>
                      <Col sm={9}>
                      <InputGroup>
                        <div className="input-group-text">@</div>
                        <input
                          type="email"
                          name="ur_email"
                          className="form-control"
                          id="autoSizingInputGroup"
                          placeholder=""
                          value={formik.values.ur_email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur} />
                      </InputGroup>
                      {
                        formik.errors.ur_email && formik.touched.ur_email ? (
                          <span className="text-danger">{formik.errors.ur_email}</span>
                        ) : null
                      }
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label className="form-check-label" htmlFor="autoSizingInputGroup">Your Phone</Label>
                      <Col sm={9}>
                      <InputGroup>
                        <div className="input-group-text">#</div>
                        <input
                          type="tel"
                          name="ur_phone"
                          className="form-control"
                          id="autoSizingInputGroup"
                          placeholder=""
                          value={formik.values.ur_phone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur} />
                      </InputGroup>
                      {
                        formik.errors.ur_phone && formik.touched.ur_phone ? (
                          <span className="text-danger">{formik.errors.ur_phone}</span>
                        ) : null
                      }
                      </Col>
                    </Row>
                    <Row className="mb-4" >
                      <label className="form-check-label" htmlFor="stateSelect">Your State of Residence</label>
                      <Col sm={9}>
                      <select className="form-select" name="ur_state" id="stateSelect" 
                        value={formik.values.ur_state}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option defaultValue="">Choose...</option>
                        <option value="Alabama">Alabama</option>
                        <option value="Alaska">Alaska</option>
                        <option value="Arizona">Arizona</option>
                        <option value="Arkansas">Arkansas</option>
                        <option value="California">California</option>
                        <option value="Colorado">Colorado</option>
                        <option value="Connecticut">Connecticut</option>
                        <option value="Delaware">Delaware</option>
                        <option value="District Of Columbia">District Of Columbia</option>
                        <option value="Florida">Florida</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Idaho">Idaho</option>
                        <option value="Illinois">Illinois</option>
                        <option value="Indiana">Indiana</option>
                        <option value="Iowa">Iowa</option>
                        <option value="Kansas">Kansas</option>
                        <option value="Kentucky">Kentucky</option>
                        <option value="Louisiana">Louisiana</option>
                        <option value="Maine">Maine</option>
                        <option value="Maryland">Maryland</option>
                        <option value="Massachusetts">Massachusetts</option>
                        <option value="Michigan">Michigan</option>
                        <option value="Minnesota">Minnesota</option>
                        <option value="Mississippi">Mississippi</option>
                        <option value="Missouri">Missouri</option>
                        <option value="Montana">Montana</option>
                        <option value="Nebraska">Nebraska</option>
                        <option value="Nevada">Nevada</option>
                        <option value="New Hampshire">New Hampshire</option>
                        <option value="New Jersey">New Jersey</option>
                        <option value="New Mexico">New Mexico</option>
                        <option value="New York">New York</option>
                        <option value="North Carolina">North Carolina</option>
                        <option value="North Dakota">North Dakota</option>
                        <option value="Ohio">Ohio</option>
                        <option value="Oklahoma">Oklahoma</option>
                        <option value="Oregon">Oregon</option>
                        <option value="Pennsylvania">Pennsylvania</option>
                        <option value="Rhode Island">Rhode Island</option>
                        <option value="South Carolina">South Carolina</option>
                        <option value="South Dakota">South Dakota</option>
                        <option value="Tennessee">Tennessee</option>
                        <option value="Texas">Texas</option>
                        <option value="Utah">Utah</option>
                        <option value="Vermont">Vermont</option>
                        <option value="Virginia">Virginia</option>
                        <option value="Washington">Washington</option>
                        <option value="West Virginia">West Virginia</option>
                        <option value="Wisconsin">Wisconsin</option>
                        <option value="Wyoming">Wyoming</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Guam">Guam</option>
                        <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                        <option value="Virgin Islands">Virgin Islands</option>
                      </select>
                      {
                        formik.errors.ur_state && formik.touched.ur_state ? (
                          <span className="text-danger">{formik.errors.ur_state}</span>
                        ) : null
                      }
                      </Col>
                    </Row>
                    <Label className="form-check-label" >Your Advisor: </Label>
                    <Row className="mb-4">
                      <Label className="form-check-label" htmlFor="">Advisor's First Name</Label>
                      <Col sm={9}>
                      <Input type="text" name="lw_fname" className="form-control" id="autoSizingLWFNInput" placeholder="Atticus"
                        value={formik.values.lw_fname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={
                          formik.touched.lw_fname && formik.errors.lw_fname ? true : false
                        } />
                      {
                        formik.errors.lw_fname && formik.touched.lw_fname ? (
                          <FormFeedback type="invalid">{formik.errors.lautoSizingLWFNInputw_fname}</FormFeedback>
                        ) : null
                      }
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label className="form-check-label" htmlFor="autoSizingLWLNInput">Advisor's Last Name</Label>
                      <Col sm={9}>
                      <Input type="text" name="lw_lname" className="form-control" id="autoSizingLWLNInput" placeholder="Finch"
                        value={formik.values.lw_lname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={
                          formik.touched.lw_lname && formik.errors.lw_lname ? true : false
                        } />
                      {
                        formik.errors.lw_lname && formik.touched.lw_lname ? (
                          <FormFeedback type="invalid">{formik.errors.lw_lname}</FormFeedback>
                        ) : null
                      }
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label className="form-check-label" htmlFor="autoSizingFirmInput">Advisor's Firm Name</Label>
                      <Col sm={9}>
                      <Input type="text" name="lw_firm" className="form-control" id="autoSizingFirmInput" placeholder=""
                        value={formik.values.lw_firm}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        invalid={
                          formik.touched.lw_firm && formik.errors.lw_firm ? true : false
                        } />
                      {
                        formik.errors.lw_firm && formik.touched.lw_firm ? (
                          <FormFeedback type="invalid">{formik.errors.lw_firm}</FormFeedback>
                        ) : null
                      }
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label className="form-check-label" htmlFor="autoSizingEmailInput">Advisor's Email</Label>
                      <Col sm={9}>
                      <InputGroup>
                        <div className="input-group-text">@</div>
                        <input
                          type="email"
                          name="lw_email"
                          className="form-control"
                          id="autoSizingEmailInput"
                          placeholder=""
                          value={formik.values.lw_email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur} />
                      </InputGroup>
                      {
                        formik.errors.lw_email && formik.touched.lw_email ? (
                          <span className="text-danger">{formik.errors.lw_email}</span>
                        ) : null
                      }
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label className="form-check-label" htmlFor="autoSizingInputGroup">Advisor's Phone</Label>
                      <Col sm={9}>
                      <InputGroup>
                        <div className="input-group-text">#</div>
                        <input
                          type="tel"
                          name="lw_phone"
                          className="form-control"
                          id="autoSizingInputGroup"
                          placeholder=""
                          value={formik.values.lw_phone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur} />
                      </InputGroup>
                      {
                        formik.errors.lw_phone && formik.touched.lw_phone ? (
                          <span className="text-danger">{formik.errors.lw_phone}</span>
                        ) : null
                      }
                      </Col>
                    </Row>
                    <Row className="mb-4" >
                      <div className="col mt-3">
                        <ClientCaptcha backgroundColor="#F2F2F2" fontSize="18" fontFamily="DejaVu Sans Mono" charsCount='5' captchaCode={setCaptchaCode} />
                      </div>
                      <div className="col mt-3">
                          <div>
                                <p>Attention: captcha is case sensitive.</p> 
                                <input style={{fontFamily:"DejaVu Sans Mono"}}
                                    placeholder="Enter Captcha Value" 
                                    id="user_captcha_input" 
                                    name="user_captcha_input" 
                                    value={userCaptchaInput} 
                                    type="text"
                                    onChange={(event) => {
                                        setUserCaptchaInput(event.target.value)
                                      }}
                                    />
                            </div>
                      </div>
                    </Row>

                    <div className="col-sm-auto">
                      <button type="submit" className="btn btn-primary w-md">Submit</button>
                    </div>
                  </Form>
                  }
                </CardBody>
              </Card>
            </div>
    </React.Fragment>
  )
}
export default FormMarketing
