import { combineReducers } from "redux";

// Front
// import LayoutReducer from "./layouts/reducer";
import CryptoReducer from "./crypto/reducer";
import ContactsReducer from "./contacts/reducer"
import LayoutReducer from "./layouts/reducer";
import EmailReducer from "./email/reducer";
import BqETHReducer from "./bqeth/reducer";

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    crypto: CryptoReducer,
    contacts: ContactsReducer,  
    email: EmailReducer,
    bqeth: BqETHReducer,
});

export default rootReducer;