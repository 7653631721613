import DOMPurify from 'dompurify';


    // Called by TopicLinkComponent to replace Links within non-image parts
const PlainTextComponent = ({ content }) => {

        const renderContent = () => {
          if (content) {
              // This is the fall through default behavior for Text inside Topics
              const sanitizedContent = DOMPurify.sanitize(content);
              const key = 'C-'+content;
              return <span key={key} dangerouslySetInnerHTML={{ __html: sanitizedContent }} />;
          }
          else {  // Empty topic, return something.
            return <div></div>
          }
        };
      
        return <>{renderContent()}</>;
      };

export default PlainTextComponent;