import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Collapse, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TopicItem, getBqETHTopicThunk, getBqETHPartnerReadyThunk, visitBqETHTopicThunk, getBqETHPartnerQuizThunk } from "slices/thunk";
import { useCookies } from "react-cookie";
import ImageComponent from "../RenderComponents/ImageComponent";
import { QuizItem, getBqETHQuizThunk } from "slices/thunk";
import DOMPurify from 'dompurify';

type BulletListItem = {
  key: string;
  value: string;
};


type QuestionType = {
  question: string;
  bulletList: BulletListItem[];
};

function parseQuestionAndBulletList(inputString: string): QuestionType {
  const result: QuestionType = {
    question: '',
    bulletList: []
  };

  const regex = /^(.*):\s*(.*)$/;
  const questionMatch = inputString.match(regex);

  if (questionMatch) {
    console.log("Regexp match for "+ inputString)
    result.question = questionMatch[1].trim();
    const bulletListString = questionMatch[2];

    // Matches [digit]  Text  
    const bulletRegex = /\[(\d+)\]\s*(.*?)(?=(?:\[\d+\]|\s*\.\s*|$))/g;
    let bulletMatch;

    while ((bulletMatch = bulletRegex.exec(bulletListString)) !== null) {
      const [, key, value] = bulletMatch;
      result.bulletList.push({ key, value: value.trim() });
    }
  } 
  else {
    console.log("No regexp match for "+ inputString)
  }

  return result;
}

// Props here is a list of topic entries :
// topic_id: item.fields.topic_id, 
// order: item.fields.order,
// question: item.fields.FullTitle, 
// answer: item.fields.Component,
// allowQuiz: true 
const BqETHFaqQuiz = (props: any) => {

  const dispatch = useDispatch<any>();
  const [cookies, ] = useCookies(['userUuid']);

  const [rows, setRows] = useState(
    Array.from({ length: props.entries.length }, (_, index) => index === -1)  // Nothing open by default
  );
  const [currentTopicId, setCurrentTopicId] = useState<number|undefined>();
  const [currentTopic, setCurrentTopic] = useState<TopicItem|undefined>();
  const [question, setQuestion] = useState<QuestionType>();
  const [questionIdx, setQuestionIdx] = useState<number>(0);
  const [fetchingQuiz, setFetchingQuiz] = useState(false);
  const [fetchingUserQuiz, setFetchingUserQuiz] = useState(false);
  const [userQuiz, setUserQuiz] = useState(false);
  // const [userReady, setUserReady] = useState(false);
  const [answered, setAnswered] = useState<boolean>(false);
  const [rightAnswer, setRightAnswer] = useState<boolean>(false);
  const [answerMap, setAnswerMap] = useState<Map<number,boolean>>(new Map<number,boolean>());

  const updateAnswerMap = (key: number, value: boolean) => {
    setAnswerMap(map => new Map(map.set(key, value)));
  }

  // Opens and closes the Topic accordeon rows
  const toggleRow = (index: number) => {
    setRows((prevRow) =>
      prevRow.map((col, i) => (i === index ? !col : false))
    );
    // Make sure we record that the topic was visited 
    if (rows[index]) {
      setCurrentTopicId(undefined); // Was open, being closed
    }
    else {
      setFetchingQuiz(true);
      setCurrentTopicId(props.entries[index].topic);  // Was closed, being opened
    }
  };

  // cur_t is the result of a dispatch to fetch a topic item
  // local_cur_t is the local replica we make, so we can cache it
  const cur_t: TopicItem = useSelector((state: any) => state.bqeth.topics);
  const [local_cur_t, set_local_cur_t] = useState(cur_t);
  useEffect(() => {set_local_cur_t(cur_t)},[cur_t]);

  const qiz: QuizItem[] = useSelector((state: any) => state.bqeth.quiz);
  const [local_qiz, set_local_qiz] = useState(qiz);
  useEffect(() => {set_local_qiz(qiz)},[qiz]);

  const user_q_answered: boolean = useSelector((state: any) => state.bqeth.partner_quiz_answered);
  const user_q_correct: boolean = useSelector((state: any) => state.bqeth.partner_quiz_correct);
  // const user_q_score: number = useSelector((state: any) => state.bqeth.partner_score);
  const user_q_topic: number = useSelector((state: any) => state.bqeth.partner_quiz_topic);

  // From [min,max[
  const randomInt = (min, max) =>  Math.floor(Math.random() * (max - min)) + min; 

  useEffect(() => {
    const topicItem = localStorage.getItem('topic-'+currentTopicId);
    if (topicItem) {
      console.log("Fetching topic Item from local storage.")
      set_local_cur_t(JSON.parse(topicItem));
    }
    else if (currentTopicId) {
      dispatch(getBqETHTopicThunk({ // Gets topic
        topic: currentTopicId
      }));
    }
  }, [currentTopicId, dispatch]);

  useEffect(() => {
    if (local_cur_t) {
      localStorage.setItem('topic-'+currentTopicId, JSON.stringify(local_cur_t));
      setCurrentTopic(local_cur_t);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [local_cur_t]);

  useEffect(() => {
    /* Fetch the quiz for the current topic if any */
    if (currentTopic?.fields.quiz) {
      console.log("Current topic changed. Has a quiz: fetching the quiz");
      const qizItem = localStorage.getItem('quiz-'+currentTopicId);
      if (qizItem) {
        console.log("Fetching quiz Item from local storage.")
        const qItem = JSON.parse(qizItem);
        set_local_qiz(qItem);
        const index = localStorage.getItem('questionIdx-'+currentTopicId);
        if (index) {
          const qIndex: number = JSON.parse(index)
          const question: QuestionType = parseQuestionAndBulletList(qItem[qIndex].fields.question_content);
          setQuestionIdx(qIndex);
          setQuestion(question);
          setFetchingUserQuiz(true);
          setFetchingQuiz(false);
        }
      }
      else {
        dispatch(getBqETHQuizThunk({  // Gets Quiz
          quiz: currentTopic.fields.quiz
        }));
        setFetchingQuiz(true);
      }
    }
    else {
      console.log("Current topic changed. No quiz");
      setQuestionIdx(0);
      setQuestion(undefined);
      setAnswered(false);
      setFetchingQuiz(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTopic, dispatch]); 

  useEffect(() => {
    console.log("qiz changed.");
      /* Interpret the quiz, select a random question, create answer sets */
    if (local_qiz && local_qiz.length > 0) {  // An Array of questions
      localStorage.setItem('quiz-'+currentTopicId, JSON.stringify(local_qiz));
      // Pick a random question
      const rnd: number = randomInt(0,local_qiz.length);
      localStorage.setItem('questionIdx-'+currentTopicId, JSON.stringify(rnd));
      const question: QuestionType = parseQuestionAndBulletList(local_qiz[rnd].fields.question_content);
      setQuestionIdx(rnd);
      setQuestion(question);
      setAnswered(false);
      console.log("Picked a question. Dispatching the check for user's answer.");
      // We know we need to check the user's response on this one
      dispatch(getBqETHPartnerQuizThunk({ // Gets User-Quiz record
        user_hash: cookies['userUuid'], 
        topic: currentTopicId
      }));
      setFetchingUserQuiz(true);
      setFetchingQuiz(false);
    }
    else {
      console.log("qiz changed to undefined. Resetting answered.");
      setQuestionIdx(0);
      setQuestion(undefined);
      setAnswered(false);
      setFetchingQuiz(false);
    }
  }, [local_qiz]); 

  useEffect(() => {
    if (user_q_answered != undefined) {
      console.log("user_q_answered changed to :", user_q_answered);
      setUserQuiz(user_q_answered);
      setAnswered(user_q_answered)
      if (currentTopicId && user_q_answered) {
        updateAnswerMap(user_q_topic, user_q_correct)
      }
    }
    else {
      console.log("user_q_answered changed to undefined. Resetting answered.");
      setAnswered(false);
    }
    setFetchingUserQuiz(false);
  }, [user_q_topic, user_q_answered]); 


      // HANDLER FUNCTIONS  
  const getCurrentDate= () => {
    return new Date().toISOString();
  }

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  const answer = async (id: number) => {
    console.log("Capturing Answer : ", id);
    setAnswered(true);
    var correct: boolean = (local_qiz[questionIdx].fields.correct_answer_is == id+1);
    setRightAnswer(correct);
    if (currentTopicId) {
      updateAnswerMap(currentTopicId, correct)
    }
    console.log("Answer is: ", correct);
    dispatch(visitBqETHTopicThunk({ 
      user_hash: cookies['userUuid'],     
      date: getCurrentDate(),
      topic: currentTopicId,
      quiz_good: correct
    }));
    await sleep(3000);
    dispatch(getBqETHPartnerReadyThunk({ user_hash: cookies['userUuid']}));
  }

    const TopicComponent = ({ content }) => {

      const renderContent = () => {
        if (content) {
          const regex = /(\[T\d+T*\])/g;  // Matches Topic links [T23] or [T0T]  
          const parts = content.split(regex);
  
          if (parts) {
            return parts.map((part, index) => {
              if (regex.test(part)) {
                const tn = part.match(/\d+/)[0];
                const key = 'IT-'+index;
                return (
                    <button key={key} className="anchor-button" 
                    onClick={() => {
                        console.log(tn);
                        // setModalTopicId(Number(tn));
                        // setTopicmodal(true);
                      }}>
                      here
                    </button>
                );
              } 
              else {
                const key = 'I-'+index;
                return <ImageComponent key={key} content={part} />
              }
            });
          }
        }
        else {  // Empty topic, return something.
          return <div></div>
        }
      };
    
      return <>{renderContent()}</>;
    };

  return (
    <React.Fragment>
      <div>
        <div id="gen-ques-accordion" className="accordion custom-accordion">
          {/* Iterates over topic rows */}
          {props.entries.map((entry, index) => (
            <div className="mb-3" key={index}>
              <Link
                to="#"
                className="accordion-list"
                onClick={() => toggleRow(index)}
                style={{ cursor: "pointer" }}
              >
                <div>{entry.question}</div>
                {/* <div style={{color:"lightgray"}}>{entry.topic}-{entry.order}</div> */}
                <i
                  className={
                    answerMap.get(entry.topic) != undefined   // References whether the row is open or closed
                      ? 
                      (answerMap.get(entry.topic) ? "mdi mdi-thumb-up-outline accor-green": 
                        "mdi mdi-thumb-down accor-red")
                      : "mdi accor-plus-icon"
                  }
                />
                <i
                  className={
                    rows[index]   // References whether the row is open or closed
                      ? "mdi mdi-minus accor-plus-icon"
                      : "mdi mdi-plus accor-plus-icon"
                  }
                />
              </Link>
              <Collapse isOpen={rows[index]}>
                <Card className="border plan-box " styles={[{ marginTop: 16 }]}>
                  <CardBody className="p-5">
                    <TopicComponent content={entry.answer} /> 
                        {fetchingQuiz && local_qiz && question &&
                        <div><Spinner className="mr-5"color="primary"/> Loading...</div>
                        }
                        {!fetchingQuiz && fetchingUserQuiz && local_qiz && question &&
                        <div><Spinner className="mr-5" color="success"/> Checking answers ...</div>
                        }
                        {/* Quiz Section Buttons */}
                        {!fetchingQuiz && !fetchingUserQuiz && local_qiz && userQuiz && question ?
                        <div >You already answered this question.</div>
                          : null
                        }
                        {!fetchingQuiz && !fetchingUserQuiz && local_qiz && !userQuiz && question &&
                        <div id="gen-ques-accordion" className="accordion custom-accordion">
                          <div className="mb-3">
                              {/* Question Section  */}
                              {question && question.question ?
                              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.question) }} 
                              />
                              : null 
                                }
                              <br></br>
                              {/* Answer Buttons Section  */}
                              {question && question.bulletList && Object.keys(question.bulletList).length > 0 ? 
                                <div className="justify-content-center text-center mb-5">
                                      {question.bulletList.map(({ key, value }, i) => 
                                      <Button className="btn-blue-flat mb-1 me-5" type="button" 
                                        disabled={answered} key={key} onClick={() =>  answer(i)}> {key}: {value}</Button>
                                      )}
                                </div>
                                    : null 
                                }
                              {!userQuiz && answered && rightAnswer ?
                                <TopicComponent content={local_qiz[questionIdx].fields.correct_answer_text}  />
                              : null }
                              {!userQuiz && answered && !rightAnswer ?
                                <TopicComponent content={local_qiz[questionIdx].fields.incorrect_answer_text} />
                              : null 
                                }
                          </div>
                        </div> }
                  </CardBody>
                </Card>
              </Collapse>
            </div>
          ))}
        </div>
        {/* <div >
          {Array.from(answerMap.entries()).map(([key, value]) => <li key={key}>{key} : {value? 'True':'False'}</li>)}
        </div> */}
        {/* <Row className="justify-content-center">Your Score {user_q_score} </Row> */}
      </div>
    </React.Fragment>
  );
};

export default BqETHFaqQuiz;
