import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import FormMarketing from "../Forms/FormMarketing";
import FormPartner from "../Forms/FormPartner";
import FormReferral from "../Forms/FormReferral";
import FormSupport from "../Forms/FormSupport";
import PlainTextComponent from "./PlainTextComponent";
import { useState } from "react";
import FormRegister from "../Forms/FormRegister";
import FormConsulting from "../Forms/FormConsulting";
import FormInvestors from "../Forms/FormInvestors";


    // Called by TopicLinkComponent to replace Links within non-image parts
const FormButtonComponent = ({ content, text }) => {

    const [topicModal, setTopicmodal] = useState<boolean>(false);
    const toggle = () => setTopicmodal(!topicModal);
    
    const renderSwitch = (form: string) => {
      switch (form) {
        case "0":
          return (<FormReferral />);
        case "1":
          return (<FormPartner />); // TPP
        case "2":
          return (<FormSupport />);
        case "3":
          return (<FormMarketing />);
        case "4":
          return (<FormRegister />);
        case "5":
          return (<FormInvestors />);
        case "6":
          return (<FormConsulting />);
        default:
          return (<div></div> );
      }  
    };

        const renderContent = () => {
          if (content) {
            const regex = /(\[F\d+\])/g;
            const parts = content.split(regex);
    
            if (parts) {
              return parts.map((part, index) => {
                if (regex.test(part)) {
                  const n = part.match(/\d+/)[0];
                  if (!topicModal) {
                    return (<button className="anchor-button" key={'b'+n}
                        onClick={() => {
                        setTopicmodal(true);
                      }} > {text}</button> );
                  } 
                  else {
                    return (
                    <Modal isOpen={topicModal} key={'f'+n} autoFocus={true} size="lg" backdrop={true} centered toggle={toggle}>
                      <ModalBody>
                        {renderSwitch(n)}
                      </ModalBody>
                      <ModalFooter>
                          <Button color="secondary" key={'b'+n} onClick={toggle}>Close</Button>
                      </ModalFooter>
                    </Modal>
                    );
                  }
                } 
                else {
                  // This is the fall through default behavior for Text inside Topics
                  const key = 'L-'+index;
                  return <PlainTextComponent key={key} content={part} />
                }
              });
            }
          }
          else {  // Empty topic, return something.
            return <div></div>
          }
        };
      
        return <>{renderContent()}</>;
      };

export default FormButtonComponent;